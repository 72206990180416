import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteMprn, RelatedMprnModel, CompleteQuoteList, RelatedQuoteListModel } from "./index"

export const GasUsageModel = z.object({
  id: z.string(),
  manualConsumptionEntry: z.boolean(),
  totalUsage: z.number(),
  dayUsage: z.number(),
  mprnValue: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteGasUsage extends z.infer<typeof GasUsageModel> {
  mprn: CompleteMprn
  quoteList: CompleteQuoteList[]
}

/**
 * RelatedGasUsageModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedGasUsageModel: z.ZodSchema<CompleteGasUsage> = z.lazy(() => GasUsageModel.extend({
  mprn: RelatedMprnModel,
  quoteList: RelatedQuoteListModel.array(),
}))
