import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteMpan, RelatedMpanModel, CompleteQuoteList, RelatedQuoteListModel, CompleteProfile, RelatedProfileModel } from "./index"

export const ElectricitySupplierModel = z.object({
  id: z.string(),
  unitRate: z.number().nullish(),
  nightUnitRate: z.number().nullish(),
  weekendUnitRate: z.number().nullish(),
  capacityChargeKva: z.string().nullish(),
  annualPrice: z.number().nullish(),
  standingCharge: z.number().nullish(),
  unitRateUplift: z.number().nullish(),
  standingChargeUplift: z.number().nullish(),
  mpanId: z.string().nullish(),
  createdById: z.string().nullish(),
  createdAt: z.date().optional(),
  updatedAt: z.date(),
})

export interface CompleteElectricitySupplier extends z.infer<typeof ElectricitySupplierModel> {
  mpan?: CompleteMpan | null
  quoteList?: CompleteQuoteList | null
  createdBy?: CompleteProfile | null
}

/**
 * RelatedElectricitySupplierModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedElectricitySupplierModel: z.ZodSchema<CompleteElectricitySupplier> = z.lazy(() => ElectricitySupplierModel.extend({
  mpan: RelatedMpanModel.nullish(),
  quoteList: RelatedQuoteListModel.nullish(),
  createdBy: RelatedProfileModel.nullish(),
}))
