/**
 * This approach to user permissions is temporary. We will move to a RBAC approach.
 * I plan to use Supabase RBAC for this as it allow us to handle permissions at the database level.
 * Without this approach there is a window where users can perform actions if a session is revoked but hasn't expired yet.
 * The alternative here would require us to check against a Redis cached of revoked sessions which is a bit of a mess.
 */
import { usersTableActionAllowedLists } from "@/config/access";
import { UserRole } from "@prisma/client";
import { PRIVILEGED_ROLES } from "@watt/common";

export const getUserPermissions = (role: UserRole, email: string) => {
  return {
    isAllowedToEditPriceList: hasPermissionToEditPriceList(role),
    isAllowedToViewHiddenOptions: isAllowedToViewHiddenOptions(role),
    isAuthorised: hasElevatedPermissions(role, email),
    isAdmin: isAdminOrIsOnUserActionAllowedList(role, email),
    isSalesAgent: role === UserRole.SALES_AGENT,
    isManager: role === UserRole.MANAGER,
    isSystemUser: role === UserRole.SYSTEM
  };
};

const hasPermissionToEditPriceList = (role: UserRole) => {
  return (
    role === UserRole.ADMIN ||
    role === UserRole.COMPLIANCE ||
    role === UserRole.MANAGER
  );
};

const isAllowedToEditPriceList = (role?: UserRole) => {
  if (!role) {
    return false;
  }
  return (
    role === UserRole.ADMIN ||
    role === UserRole.COMPLIANCE ||
    role === UserRole.MANAGER
  );
};

const hasElevatedPermissions = (role: UserRole, email: string) => {
  return (
    (role &&
      (
        [
          UserRole.ADMIN,
          UserRole.COMPLIANCE,
          UserRole.MANAGER,
          UserRole.FINANCE,
          UserRole.DIRECTOR
        ] as UserRole[]
      ).includes(role)) ||
    (!!email && usersTableActionAllowedLists.includes(email))
  );
};

const isAdminOrIsOnUserActionAllowedList = (role: UserRole, email: string) => {
  return (
    role === UserRole.ADMIN || usersTableActionAllowedLists.includes(email)
  );
};

export const isAllowedToViewHiddenOptions = (role?: UserRole) => {
  if (!role) {
    return false;
  }
  const userRole = UserRole[role];
  return PRIVILEGED_ROLES.has(userRole);
};
