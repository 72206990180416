import * as z from "zod"
import * as imports from "../zod-schemas"
import { CallStatus } from "@prisma/client"

export const EnqueuedCallInstanceModel = z.object({
  /**
   * The unique string that that we created to identify the Recording resource.
   */
  callSid: z.string(),
  /**
   * The unique identifier for the account.
   */
  accountSid: z.string(),
  /**
   * The API version used for this call.
   */
  apiVersion: z.string(),
  /**
   * The unique identifier for the queue.
   */
  queueName: z.string(),
  /**
   * The current status of the call.
   */
  callStatus: z.nativeEnum(CallStatus),
  /**
   * The direction of the call (inbound or outbound).
   */
  direction: z.string(),
  /**
   * The phone number, SIP address or Client identifier that made this call.
   */
  from: z.string(),
  /**
   * The city of the call origin.
   */
  fromCity: z.string().nullish(),
  /**
   * The country code of the call origin.
   */
  fromCountry: z.string(),
  /**
   * The state of the call origin.
   */
  fromState: z.string().nullish(),
  /**
   * The zip code of the call origin.
   */
  fromZip: z.string().nullish(),
  /**
   * The phone number that received this call.
   */
  called: z.string(),
  /**
   * The city where the call was made to.
   */
  calledCity: z.string().nullish(),
  /**
   * The country code where the call was made to.
   */
  calledCountry: z.string().nullish(),
  /**
   * The state the call was made from.
   */
  calledState: z.string().nullish(),
  /**
   * The zip code where the call was made to.
   */
  calledZip: z.string().nullish(),
  /**
   * The phone number that this call was made to.
   */
  to: z.string(),
  /**
   * The city where the call was received.
   */
  toCity: z.string().nullish(),
  /**
   * The country code where the call was received.
   */
  toCountry: z.string(),
  /**
   * The state the call was received in.
   */
  toState: z.string(),
  /**
   * The zip code where the call was received.
   */
  toZip: z.string().nullish(),
  /**
   * The phone number that made the call.
   */
  caller: z.string(),
  /**
   * The city of the caller.
   */
  callerCity: z.string().nullish(),
  /**
   * The country code of the caller.
   */
  callerCountry: z.string().nullish(),
  /**
   * The state of the caller.
   */
  callerState: z.string().nullish(),
  /**
   * The zip code of the caller.
   */
  callerZip: z.string().nullish(),
  /**
   * The unique token for the call.
   */
  callToken: z.string().nullish(),
  /**
   * Timestamp when this record was created.
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when this record was last updated.
   */
  updatedAt: z.date().optional(),
})
