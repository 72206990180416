import * as z from "zod"
import * as imports from "../zod-schemas"
import { UtilityType, ProviderStatus } from "@prisma/client"
import { CompleteQuote, RelatedQuoteModel, CompleteQuoteList, RelatedQuoteListModel, CompleteVerbalContractTemplate, RelatedVerbalContractTemplateModel, CompletePriceList, RelatedPriceListModel } from "./index"

export const ProviderModel = z.object({
  /**
   * Unique identifier for the provider
   */
  id: z.string().optional(),
  /**
   * Name of the provider
   */
  name: z.string(),
  /**
   * Whether the provider is enabled or not
   */
  isEnabled: z.boolean(),
  /**
   * Whether the provider supports dual fuel or not
   */
  isDualFuel: z.boolean().optional(),
  /**
   * Whether the provider is green or not
   */
  isGreen: z.boolean().optional(),
  /**
   * Whether the provider supports prepayment or not
   */
  isPrepay: z.boolean().optional(),
  /**
   * Whether a smart meter is required for the provider
   */
  smartMeterRequired: z.boolean().optional(),
  /**
   * Market Participant ID (MPID)
   */
  mpid: z.string().nullish(),
  /**
   * RECCO ID (if available)
   */
  reccoId: z.string().nullish(),
  /**
   * UDCORE ID (if available)
   */
  udcoreId: z.string().nullish(),
  /**
   * List of utilities provided by the provider
   */
  utilitiesProvided: z.nativeEnum(UtilityType).array(),
  /**
   * Name of the logo file for the provider
   */
  logoFileName: z.string().nullish(),
  /**
   * Whether the provider is displayed on the current supplier list
   */
  isDisplayedOnCurrentSupplierList: z.boolean().optional(),
  /**
   * Status of the provider
   */
  status: z.nativeEnum(ProviderStatus).nullish(),
  /**
   * Timestamp when the provider was created
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when the provider was last updated
   */
  updatedAt: z.date().optional(),
})

export interface CompleteProvider extends z.infer<typeof ProviderModel> {
  quotes: CompleteQuote[]
  quoteLists: CompleteQuoteList[]
  verbalContractTemplates: CompleteVerbalContractTemplate[]
  priceLists: CompletePriceList[]
}

/**
 * RelatedProviderModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProviderModel: z.ZodSchema<CompleteProvider> = z.lazy(() => ProviderModel.extend({
  quotes: RelatedQuoteModel.array(),
  quoteLists: RelatedQuoteListModel.array(),
  verbalContractTemplates: RelatedVerbalContractTemplateModel.array(),
  priceLists: RelatedPriceListModel.array(),
}))
