import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteQuote, RelatedQuoteModel } from "./index"

export const ElectricityQuoteModel = z.object({
  id: z.number().int(),
  quoteId: z.string(),
  unitRate: z.number(),
  nightUnitRate: z.number().nullish(),
  weekendUnitRate: z.number().nullish(),
  capacityChargeKva: z.string().nullish(),
  annualPrice: z.number(),
  standingCharge: z.number(),
  contractType: z.string(),
  unitRateUplift: z.number(),
  isBespokeUplift: z.boolean(),
  standingChargeUplift: z.number().nullish(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteElectricityQuote extends z.infer<typeof ElectricityQuoteModel> {
  quote: CompleteQuote
}

/**
 * RelatedElectricityQuoteModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedElectricityQuoteModel: z.ZodSchema<CompleteElectricityQuote> = z.lazy(() => ElectricityQuoteModel.extend({
  quote: RelatedQuoteModel,
}))
