import * as z from "zod"
import * as imports from "../zod-schemas"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const CommercialDelphiScoreModel = z.object({
  /**
   * Unique identifier for the commercial delphi score entry
   */
  id: z.string().optional(),
  /**
   * Reference identifier for the business
   */
  companyNumber: z.string(),
  /**
   * Commercial Delphi score
   */
  score: z.number().int(),
  /**
   * Commercial Delphi band
   */
  band: z.string(),
  /**
   * Textual description of the Commercial Delphi band
   */
  bandText: z.string(),
  /**
   * Stability odds of the business
   */
  stabilityOdds: z.string(),
  /**
   * Indicator for manual override in scoring
   */
  overrideIndicator: z.string(),
  /**
   * Credit rating assigned to the business
   */
  creditRating: z.number().int(),
  /**
   * Indicator if the credit rating was overridden
   */
  creditRatingOverrideIndicator: z.string(),
  /**
   * Credit limit suggested for the business
   */
  creditLimit: z.number().int(),
  /**
   * Indicator if the credit limit was overridden
   */
  creditLimitOverrideIndicator: z.string(),
  /**
   * Indicator if the business is a parent company
   */
  parentIndicator: z.string().nullish(),
  /**
   * Indicator for manual override in credit text
   */
  creditTextOverrideIndicator: z.string(),
  /**
   * Conclusion text summarizing the business's creditworthiness
   */
  conclusionText: z.string(),
  /**
   * Historical scores and data in JSON format
   */
  history: imports.CommercialDelphiScoreHistorySchema,
  /**
   * Timestamp of when the record was created
   */
  createdAt: z.date().optional(),
})
