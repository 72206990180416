import * as z from "zod"
import * as imports from "../zod-schemas"
import { CallStatus } from "@prisma/client"
import { CompleteRecordingInstance, RelatedRecordingInstanceModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const CallInstanceModel = z.object({
  /**
   * The unique string that we created to identify this Call resource.
   */
  sid: z.string(),
  /**
   * The date and time in GMT that this resource was created specified in RFC 2822 format.
   */
  dateCreated: z.date(),
  /**
   * The date and time in GMT that this resource was last updated, specified in RFC 2822 format.
   */
  dateUpdated: z.date(),
  /**
   * The SID of the Account that created this Call resource.
   */
  accountSid: z.string(),
  /**
   * The phone number, SIP address, Client identifier or SIM SID that received this call.
   */
  to: z.string().nullish(),
  /**
   * The phone number, SIP address or Client identifier that received this call. Formatted for display.
   */
  toFormatted: z.string().nullish(),
  /**
   * The identity name if the to number is a known number
   */
  toIdentity: z.string().nullish(),
  /**
   * The phone number, SIP address, Client identifier or SIM SID that made this call.
   */
  from: z.string(),
  /**
   * The calling phone number, SIP address, or Client identifier formatted for display.
   */
  fromFormatted: z.string(),
  /**
   * The identity name if the from number is a known number
   */
  fromIdentity: z.string().nullish(),
  /**
   * If the call was inbound, this is the SID of the IncomingPhoneNumber resource that received the call.
   */
  phoneNumberSid: z.string().nullish(),
  /**
   * The current status of the call.
   */
  status: z.nativeEnum(CallStatus),
  /**
   * The start time of the call.
   */
  startTime: z.date(),
  /**
   * The time the call ended.
   */
  endTime: z.date().nullish(),
  /**
   * The length of the call in seconds.
   */
  duration: z.string(),
  /**
   * The charge for this call, in the currency associated with the account.
   */
  price: z.string().nullish(),
  /**
   * The currency in which `Price` is measured, in ISO 4127 format.
   */
  priceUnit: z.string(),
  /**
   * A string describing the direction of the call.
   */
  direction: z.string(),
  /**
   * Either `human` or `machine` if this call was initiated with answering machine detection. Empty otherwise.
   */
  answeredBy: z.string().nullish(),
  /**
   * The API version used to create the call.
   */
  apiVersion: z.string(),
  /**
   * The forwarding phone number if this call was an incoming call forwarded from another number.
   */
  forwardedFrom: z.string().nullish(),
  /**
   * The Group SID associated with this call.
   */
  groupSid: z.string().nullish(),
  /**
   * The caller's name if this call was an incoming call to a phone number with caller ID Lookup enabled. Otherwise, empty.
   */
  callerName: z.string().nullish(),
  /**
   * The wait time in milliseconds before the call is placed.
   */
  queueTime: z.string().nullish(),
  /**
   * The unique identifier of the trunk resource that was used for this call.
   */
  trunkSid: z.string(),
  /**
   * The URI of this resource, relative to `https://api.twilio.com`.
   */
  uri: z.string(),
  /**
   * A list of subresources available to this call, identified by their URIs relative to `https://api.twilio.com`.
   */
  subresourceUris: jsonSchema,
  /**
   * Timestamp when the user was created
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when the user was last updated
   */
  updatedAt: z.date().optional(),
  /**
   * The SID that identifies the call that created this leg.
   */
  parentCallSid: z.string().nullish(),
})

export interface CompleteCallInstance extends z.infer<typeof CallInstanceModel> {
  recordings: CompleteRecordingInstance[]
  parentCall?: CompleteCallInstance | null
  childCalls: CompleteCallInstance[]
}

/**
 * RelatedCallInstanceModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCallInstanceModel: z.ZodSchema<CompleteCallInstance> = z.lazy(() => CallInstanceModel.extend({
  recordings: RelatedRecordingInstanceModel.array(),
  parentCall: RelatedCallInstanceModel.nullish(),
  childCalls: RelatedCallInstanceModel.array(),
}))
