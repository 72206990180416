import * as z from "zod"
import * as imports from "../zod-schemas"
import { BusinessType } from "@prisma/client"
import { CompleteBusinessTargetSearchSummary, RelatedBusinessTargetSearchSummaryModel, CompleteCompany, RelatedCompanyModel, CompleteProfile, RelatedProfileModel } from "./index"

export const BusinessTargeterModel = z.object({
  /**
   * Unique identifier for the business reference (Registerd Number / URN) of the business
   */
  businessRef: z.string(),
  /**
   * Commercial name of the business
   */
  commercialName: z.string(),
  /**
   * The legal status of the business
   */
  legalStatus: z.string(),
  /**
   * Operational status of the business
   */
  businessStatus: z.string(),
  /**
   * Type of the company calculated from business reference and commercial name
   */
  businessType: z.nativeEnum(BusinessType),
  /**
   * Main Activities derived from Thomson category code of the business (Non Limited businesses only)
   */
  businessActivities: z.string().nullish(),
  /**
   * Business name where a name other than the current registered name contributed to the matching
   */
  name: z.string().nullish(),
  /**
   * Type of the name - ‘RC’ = Registered name (current); ‘RP’ = Previous registered name; ‘TC’ = Trading name (limited); ‘NL’ = Trading name (non-limited)
   */
  nameType: z.string().nullish(),
  /**
   * First line of the business address
   */
  locationLine1: z.string().nullish(),
  /**
   * Second line of the business address
   */
  locationLine2: z.string().nullish(),
  /**
   * Third line of the business address
   */
  locationLine3: z.string().nullish(),
  /**
   * Fourth line of the business address
   */
  locationLine4: z.string().nullish(),
  /**
   * Fifth line of the business address
   */
  locationLine5: z.string().nullish(),
  /**
   * Postal code of the business address
   */
  postcode: z.string().nullish(),
  sicType: z.string().nullish(),
  /**
   * Standard Industrial Classification code
   */
  sicCode: z.string().nullish(),
  /**
   * Description for the SIC code
   */
  sicDescription: z.string().nullish(),
  /**
   * Matched post code of the business returned from the Business Targeter search
   */
  matchedPostcode: z.string().nullish(),
  /**
   * Matched telephone Number of the business returned from the Business Targeter search
   */
  matchedPhoneNumber: z.string().nullish(),
  createdById: z.string().nullish(),
  /**
   * Timestamp of when the record was created
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp of when the record was last updated
   */
  updatedAt: z.date().optional(),
})

export interface CompleteBusinessTargeter extends z.infer<typeof BusinessTargeterModel> {
  businessTargetSearchSummaries: CompleteBusinessTargetSearchSummary[]
  companies: CompleteCompany[]
  createdBy?: CompleteProfile | null
}

/**
 * RelatedBusinessTargeterModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedBusinessTargeterModel: z.ZodSchema<CompleteBusinessTargeter> = z.lazy(() => BusinessTargeterModel.extend({
  /**
   * Establish the many-to-many relation with BusinessTargetSearchSummary
   */
  businessTargetSearchSummaries: RelatedBusinessTargetSearchSummaryModel.array(),
  companies: RelatedCompanyModel.array(),
  createdBy: RelatedProfileModel.nullish(),
}))
