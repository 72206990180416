import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteVerbalContractTemplate, RelatedVerbalContractTemplateModel, CompleteContract, RelatedContractModel } from "./index"

export const VerbalContractModel = z.object({
  id: z.string(),
  verbalTemplateId: z.string(),
  contractId: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteVerbalContract extends z.infer<typeof VerbalContractModel> {
  verbalTemplate: CompleteVerbalContractTemplate
  contract: CompleteContract
}

/**
 * RelatedVerbalContractModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedVerbalContractModel: z.ZodSchema<CompleteVerbalContract> = z.lazy(() => VerbalContractModel.extend({
  verbalTemplate: RelatedVerbalContractTemplateModel,
  contract: RelatedContractModel,
}))
