import * as z from "zod"
import * as imports from "../zod-schemas"
import { Salutation } from "@prisma/client"
import { CompleteCompany, RelatedCompanyModel, CompleteCompanySite, RelatedCompanySiteModel, CompleteCompanyContactEmail, RelatedCompanyContactEmailModel, CompleteCompanyContactPhoneNumber, RelatedCompanyContactPhoneNumberModel, CompleteDeal, RelatedDealModel, CompleteCallback, RelatedCallbackModel, CompleteProfile, RelatedProfileModel } from "./index"

export const CompanyContactModel = z.object({
  id: z.string().optional(),
  salutation: z.nativeEnum(Salutation).nullish(),
  forename: z.string(),
  surname: z.string(),
  position: z.string(),
  dateOfBirth: z.date().nullish(),
  lastContacted: z.date().nullish(),
  isPrimaryCompanyContact: z.boolean(),
  isPrimarySiteContact: z.boolean(),
  /**
   * @deprecated use emails instead
   */
  email: z.string().nullish(),
  /**
   * @deprecated use phoneNumbers instead
   */
  phoneNumber: z.string().nullish(),
  /**
   * @deprecated use phoneNumbers instead
   */
  mobileNumber: z.string().nullish(),
  companyId: z.string(),
  siteId: z.string().nullish(),
  createdById: z.string(),
  updatedById: z.string().nullish(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteCompanyContact extends z.infer<typeof CompanyContactModel> {
  company: CompleteCompany
  site?: CompleteCompanySite | null
  emails: CompleteCompanyContactEmail[]
  phoneNumbers: CompleteCompanyContactPhoneNumber[]
  signedDeals: CompleteDeal[]
  callbacks: CompleteCallback[]
  createdBy: CompleteProfile
  updatedBy?: CompleteProfile | null
}

/**
 * RelatedCompanyContactModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCompanyContactModel: z.ZodSchema<CompleteCompanyContact> = z.lazy(() => CompanyContactModel.extend({
  company: RelatedCompanyModel,
  site: RelatedCompanySiteModel.nullish(),
  emails: RelatedCompanyContactEmailModel.array(),
  phoneNumbers: RelatedCompanyContactPhoneNumberModel.array(),
  signedDeals: RelatedDealModel.array(),
  callbacks: RelatedCallbackModel.array(),
  createdBy: RelatedProfileModel,
  updatedBy: RelatedProfileModel.nullish(),
}))
