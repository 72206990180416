import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteMprn, RelatedMprnModel, CompleteQuoteList, RelatedQuoteListModel, CompleteProfile, RelatedProfileModel } from "./index"

export const GasSupplierModel = z.object({
  id: z.string(),
  unitRate: z.number().nullish(),
  annualPrice: z.number().nullish(),
  standingCharge: z.number().nullish(),
  unitRateUplift: z.number().nullish(),
  standingChargeUplift: z.number().nullish(),
  duration: z.number().int().nullish(),
  mprnId: z.string().nullish(),
  createdById: z.string().nullish(),
  createdAt: z.date().optional(),
  updatedAt: z.date(),
})

export interface CompleteGasSupplier extends z.infer<typeof GasSupplierModel> {
  mprn?: CompleteMprn | null
  quoteList?: CompleteQuoteList | null
  createdBy?: CompleteProfile | null
}

/**
 * RelatedGasSupplierModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedGasSupplierModel: z.ZodSchema<CompleteGasSupplier> = z.lazy(() => GasSupplierModel.extend({
  mprn: RelatedMprnModel.nullish(),
  quoteList: RelatedQuoteListModel.nullish(),
  createdBy: RelatedProfileModel.nullish(),
}))
