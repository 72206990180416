import * as z from "zod"
import * as imports from "../zod-schemas"
import { UserRole, HuntGroup } from "@prisma/client"
import { CompleteAuthUser, RelatedAuthUserModel, CompleteActivityLog, RelatedActivityLogModel, CompleteNote, RelatedNoteModel, CompleteAgentActivityLog, RelatedAgentActivityLogModel, CompleteQuoteList, RelatedQuoteListModel, CompleteEmailTransaction, RelatedEmailTransactionModel, CompleteEmailEvent, RelatedEmailEventModel, CompleteCommunicationInteraction, RelatedCommunicationInteractionModel, CompleteBusinessTargeter, RelatedBusinessTargeterModel, CompleteEntityAddress, RelatedEntityAddressModel, CompleteElectricitySupplier, RelatedElectricitySupplierModel, CompleteGasSupplier, RelatedGasSupplierModel, CompleteContract, RelatedContractModel, CompleteVerbalContractTemplate, RelatedVerbalContractTemplateModel, CompleteDeal, RelatedDealModel, CompleteNotification, RelatedNotificationModel, CompletePriceList, RelatedPriceListModel, CompleteCompanyContactEmail, RelatedCompanyContactEmailModel, CompleteCompanyContactPhoneNumber, RelatedCompanyContactPhoneNumberModel, CompleteBillingAddress, RelatedBillingAddressModel, CompleteCallback, RelatedCallbackModel, CompleteCompanyContact, RelatedCompanyContactModel } from "./index"

export const ProfileModel = z.object({
  /**
   * Unique identifier for the user
   */
  id: z.string().optional(),
  /**
   * Email address of the user
   */
  email: z.string().email("Please enter a valid email address"),
  /**
   * First name of the user
   */
  forename: z.string(),
  /**
   * Last name of the user
   */
  surname: z.string(),
  /**
   * Direct dial number of the user
   */
  directDial: z.string().optional().nullish(),
  /**
   * Direct dial number of the user in E.164 format
   */
  directDialE164: z.string().optional().nullish(),
  /**
   * Role of the user in the company
   */
  role: z.nativeEnum(UserRole),
  /**
   * Hunt groups the user is assigned to
   */
  huntGroups: z.nativeEnum(HuntGroup).array().default([]).optional(),
  /**
   * Whether the users account is disabled or not
   */
  disabled: z.boolean().nullish(),
  /**
   * Unique identifier for the authenticated user
   */
  userId: z.string().optional(),
  /**
   * Timestamp when the user was created
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when the user was last updated
   */
  updatedAt: z.date().optional(),
})

export interface CompleteProfile extends z.infer<typeof ProfileModel> {
  user: CompleteAuthUser
  activities: CompleteActivityLog[]
  notes: CompleteNote[]
  hiddenUpdatedNotes: CompleteNote[]
  agentActivityLog: CompleteAgentActivityLog[]
  quoteList: CompleteQuoteList[]
  emailTransactionList: CompleteEmailTransaction[]
  emailEvent: CompleteEmailEvent[]
  communicationInteractions: CompleteCommunicationInteraction[]
  businessTargets: CompleteBusinessTargeter[]
  addresses: CompleteEntityAddress[]
  electricitySupplier: CompleteElectricitySupplier[]
  gasSupplier: CompleteGasSupplier[]
  contracts: CompleteContract[]
  verbalContractTemplates: CompleteVerbalContractTemplate[]
  deals: CompleteDeal[]
  notifications: CompleteNotification[]
  priceLists: CompletePriceList[]
  companyContactEmails: CompleteCompanyContactEmail[]
  companyContactPhoneNumbers: CompleteCompanyContactPhoneNumber[]
  billingAddresses: CompleteBillingAddress[]
  createdCallbacks: CompleteCallback[]
  updatedCallbacks: CompleteCallback[]
  createdContacts: CompleteCompanyContact[]
  updatedContacts: CompleteCompanyContact[]
}

/**
 * RelatedProfileModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedProfileModel: z.ZodSchema<CompleteProfile> = z.lazy(() => ProfileModel.extend({
  user: RelatedAuthUserModel,
  /**
   * User activities
   */
  activities: RelatedActivityLogModel.array(),
  /**
   * User notes
   */
  notes: RelatedNoteModel.array(),
  hiddenUpdatedNotes: RelatedNoteModel.array(),
  agentActivityLog: RelatedAgentActivityLogModel.array(),
  quoteList: RelatedQuoteListModel.array(),
  emailTransactionList: RelatedEmailTransactionModel.array(),
  emailEvent: RelatedEmailEventModel.array(),
  communicationInteractions: RelatedCommunicationInteractionModel.array(),
  businessTargets: RelatedBusinessTargeterModel.array(),
  addresses: RelatedEntityAddressModel.array(),
  electricitySupplier: RelatedElectricitySupplierModel.array(),
  gasSupplier: RelatedGasSupplierModel.array(),
  contracts: RelatedContractModel.array(),
  verbalContractTemplates: RelatedVerbalContractTemplateModel.array(),
  deals: RelatedDealModel.array(),
  notifications: RelatedNotificationModel.array(),
  priceLists: RelatedPriceListModel.array(),
  companyContactEmails: RelatedCompanyContactEmailModel.array(),
  companyContactPhoneNumbers: RelatedCompanyContactPhoneNumberModel.array(),
  billingAddresses: RelatedBillingAddressModel.array(),
  createdCallbacks: RelatedCallbackModel.array(),
  updatedCallbacks: RelatedCallbackModel.array(),
  createdContacts: RelatedCompanyContactModel.array(),
  updatedContacts: RelatedCompanyContactModel.array(),
}))
