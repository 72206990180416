"use client";

import { useEffect } from "react";
import { scan } from "react-scan";

type Options = Parameters<typeof scan>[0];

export function ReactScan({ options }: { options: Options }) {
  useEffect(() => {
    scan(options);
  }, [options]);

  return null;
}
