import * as z from "zod"
import * as imports from "../zod-schemas"
import { PaymentMethod } from "@prisma/client"
import { CompleteCompanySite, RelatedCompanySiteModel } from "./index"

export const CompanyBankingDetailModel = z.object({
  id: z.string(),
  verifiedAt: z.date().nullish(),
  bankName: z.string().nullish(),
  accountHolderName: z.string().nullish(),
  accountNumber: z.string().nullish(),
  sortCode: z.string().nullish(),
  paymentMethod: z.nativeEnum(PaymentMethod),
  siteId: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteCompanyBankingDetail extends z.infer<typeof CompanyBankingDetailModel> {
  site: CompleteCompanySite
}

/**
 * RelatedCompanyBankingDetailModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCompanyBankingDetailModel: z.ZodSchema<CompleteCompanyBankingDetail> = z.lazy(() => CompanyBankingDetailModel.extend({
  site: RelatedCompanySiteModel,
}))
