import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteCallInstance, RelatedCallInstanceModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const RecordingInstanceModel = z.object({
  /**
   * The unique string that that we created to identify the Recording resource.
   */
  sid: z.string(),
  /**
   * The SID of the [Account](https://www.twilio.com/docs/iam/api/account) that created the Recording resource.
   */
  accountSid: z.string(),
  /**
   * The API version used during the recording.
   */
  apiVersion: z.string(),
  /**
   * The SID of the [Call](https://www.twilio.com/docs/voice/api/call-resource) the Recording resource is associated with. This will always refer to the parent leg of a two-leg call.
   */
  callSid: z.string(),
  /**
   * The Conference SID that identifies the conference associated with the recording, if a conference recording.
   */
  conferenceSid: z.string().nullish(),
  /**
   * The start time of the recording in GMT and in [RFC 2822](https://www.php.net/manual/en/class.datetime.php#datetime.constants.rfc2822) format.
   */
  startTime: z.date(),
  /**
   * The length of the recording in seconds.
   */
  duration: z.string(),
  /**
   * The one-time cost of creating the recording in the `price_unit` currency.
   */
  price: z.string().nullish(),
  /**
   * The currency used in the `price` property. Example: `USD`.
   */
  priceUnit: z.string(),
  status: z.string(),
  /**
   * The number of channels in the final recording file. Can be: `1` or `2`. You can split a call with two legs into two separate recording channels if you record using [TwiML Dial](https://www.twilio.com/docs/voice/twiml/dial#record) or the [Outbound Rest API](https://www.twilio.com/docs/voice/make-calls#manage-your-outbound-call).
   */
  channels: z.number().int(),
  source: z.string(),
  /**
   * The error code that describes why the recording is `absent`. The error code is described in our [Error Dictionary](https://www.twilio.com/docs/api/errors). This value is null if the recording `status` is not `absent`.
   */
  errorCode: z.number().int().nullish(),
  /**
   * The URI of the resource, relative to `https://api.twilio.com`.
   */
  uri: z.string(),
  /**
   * How to decrypt the recording if it was encrypted using [Call Recording Encryption](https://www.twilio.com/docs/voice/tutorials/voice-recording-encryption) feature.
   */
  encryptionDetails: jsonSchema,
  /**
   * A list of related resources identified by their relative URIs.
   */
  subresourceUris: jsonSchema,
  /**
   * The URL of the media file associated with this recording resource. When stored externally, this is the full URL location of the media file on Twilio
   */
  mediaUrl: z.string(),
  /**
   * The URL of the media file associated with this recording resource stored in supabase storage.
   */
  localMediaUrl: z.string().nullish(),
  /**
   * The date and time in GMT that the resource was created specified in [RFC 2822](https://www.ietf.org/rfc/rfc2822.txt) format.
   */
  dateCreated: z.date(),
  /**
   * The date and time in GMT that the resource was last updated specified in [RFC 2822](https://www.ietf.org/rfc/rfc2822.txt) format.
   */
  dateUpdated: z.date(),
})

export interface CompleteRecordingInstance extends z.infer<typeof RecordingInstanceModel> {
  callInstance: CompleteCallInstance
}

/**
 * RelatedRecordingInstanceModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedRecordingInstanceModel: z.ZodSchema<CompleteRecordingInstance> = z.lazy(() => RecordingInstanceModel.extend({
  /**
   * The Call resource associated with the recording.
   */
  callInstance: RelatedCallInstanceModel,
}))
