import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteCompany, RelatedCompanyModel } from "./index"

export const CompanyDetailModel = z.object({
  id: z.string(),
  dateIncorporated: z.date().nullish(),
  dateMovedIn: z.date().nullish(),
  website: z.string().nullish(),
  natureOfBusiness: z.string().nullish(),
  officeNumber: z.string().nullish(),
  officeNumber2: z.string().nullish(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
  companyId: z.string(),
})

export interface CompleteCompanyDetail extends z.infer<typeof CompanyDetailModel> {
  company: CompleteCompany
}

/**
 * RelatedCompanyDetailModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCompanyDetailModel: z.ZodSchema<CompleteCompanyDetail> = z.lazy(() => CompanyDetailModel.extend({
  company: RelatedCompanyModel,
}))
