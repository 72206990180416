import * as z from "zod"
import * as imports from "../zod-schemas"
import { NotificationType } from "@prisma/client"
import { CompleteCallback, RelatedCallbackModel, CompleteProfile, RelatedProfileModel } from "./index"

export const NotificationModel = z.object({
  id: z.string(),
  /**
   * Unique identifier for the Novu notification
   */
  notificationId: z.string(),
  /**
   * Type of the notification
   */
  type: z.nativeEnum(NotificationType),
  /**
   * Associated callback
   */
  callbackId: z.string().nullish(),
  createdById: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteNotification extends z.infer<typeof NotificationModel> {
  callback?: CompleteCallback | null
  createdBy: CompleteProfile
}

/**
 * RelatedNotificationModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedNotificationModel: z.ZodSchema<CompleteNotification> = z.lazy(() => NotificationModel.extend({
  callback: RelatedCallbackModel.nullish(),
  createdBy: RelatedProfileModel,
}))
