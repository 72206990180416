import * as z from "zod"
import * as imports from "../zod-schemas"
import { DealStatus } from "@prisma/client"
import { CompleteContract, RelatedContractModel, CompleteCompany, RelatedCompanyModel, CompleteCompanySite, RelatedCompanySiteModel, CompleteSiteMeter, RelatedSiteMeterModel, CompleteCompanyContact, RelatedCompanyContactModel, CompleteProfile, RelatedProfileModel } from "./index"

export const DealModel = z.object({
  id: z.string(),
  status: z.nativeEnum(DealStatus),
  isSigned: z.boolean(),
  contractId: z.string(),
  companyId: z.string(),
  siteId: z.string(),
  siteMeterId: z.string(),
  signedById: z.string(),
  createdById: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteDeal extends z.infer<typeof DealModel> {
  contract: CompleteContract
  company: CompleteCompany
  site: CompleteCompanySite
  siteMeter: CompleteSiteMeter
  signedBy: CompleteCompanyContact
  createdBy: CompleteProfile
}

/**
 * RelatedDealModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDealModel: z.ZodSchema<CompleteDeal> = z.lazy(() => DealModel.extend({
  contract: RelatedContractModel,
  company: RelatedCompanyModel,
  site: RelatedCompanySiteModel,
  siteMeter: RelatedSiteMeterModel,
  signedBy: RelatedCompanyContactModel,
  createdBy: RelatedProfileModel,
}))
