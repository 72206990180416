import * as z from "zod"
import * as imports from "../zod-schemas"
import { CallbackStatus, CallbackDeleteReason } from "@prisma/client"
import { CompleteCompanySite, RelatedCompanySiteModel, CompleteCompanyContact, RelatedCompanyContactModel, CompleteProfile, RelatedProfileModel, CompleteNotification, RelatedNotificationModel } from "./index"

export const CallbackModel = z.object({
  /**
   * Unique identifier for the callback
   */
  id: z.string().optional(),
  /**
   * Scheduled time for the callback
   */
  callbackTime: z.date(),
  /**
   * Status of the callback
   */
  status: z.nativeEnum(CallbackStatus),
  /**
   * Whether the user has been notified about the callback
   */
  notified: z.boolean(),
  /**
   * Subject for the callback
   */
  subject: z.string(),
  /**
   * Comment for the callback
   */
  comment: z.string().nullish(),
  /**
   * Associated company site
   */
  companySiteId: z.string(),
  /**
   * Associated company contact
   */
  companyContactId: z.string(),
  /**
   * User who created the callback
   */
  createdById: z.string(),
  updatedById: z.string().nullish(),
  deletionReason: z.nativeEnum(CallbackDeleteReason).nullish(),
  deletionComment: z.string().nullish(),
  /**
   * Timestamp when the callback was completed
   */
  completedAt: z.date().nullish(),
  /**
   * Timestamp when the callback was cancelled
   */
  cancelledAt: z.date().nullish(),
  /**
   * Timestamp when the callback was created
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when the callback was last updated
   */
  updatedAt: z.date().optional(),
})

export interface CompleteCallback extends z.infer<typeof CallbackModel> {
  companySite: CompleteCompanySite
  companyContact: CompleteCompanyContact
  createdBy: CompleteProfile
  updatedBy?: CompleteProfile | null
  notifications: CompleteNotification[]
}

/**
 * RelatedCallbackModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCallbackModel: z.ZodSchema<CompleteCallback> = z.lazy(() => CallbackModel.extend({
  companySite: RelatedCompanySiteModel,
  companyContact: RelatedCompanyContactModel,
  createdBy: RelatedProfileModel,
  updatedBy: RelatedProfileModel.nullish(),
  notifications: RelatedNotificationModel.array(),
}))
