import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteMprn, RelatedMprnModel, CompleteSiteMeter, RelatedSiteMeterModel } from "./index"

export const GasSiteMeterModel = z.object({
  id: z.string(),
  mprnValue: z.string(),
  siteMeterId: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteGasSiteMeter extends z.infer<typeof GasSiteMeterModel> {
  mprn: CompleteMprn
  siteMeter: CompleteSiteMeter
}

/**
 * RelatedGasSiteMeterModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedGasSiteMeterModel: z.ZodSchema<CompleteGasSiteMeter> = z.lazy(() => GasSiteMeterModel.extend({
  mprn: RelatedMprnModel,
  siteMeter: RelatedSiteMeterModel,
}))
