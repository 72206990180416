import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteEmailTransaction, RelatedEmailTransactionModel } from "./index"

export const EmailAttachmentModel = z.object({
  /**
   * Unique identifier for the email attachment
   */
  id: z.string(),
  /**
   * Unique message ID assigned by the email service provider
   */
  messageId: z.string(),
  /**
   * Filename of the attachment
   */
  filename: z.string(),
  /**
   * MIME type of the attachment
   */
  type: z.string().nullish(),
  /**
   * Disposition of the attachment (e.g., inline or attachment)
   */
  disposition: z.string().nullish(),
  /**
   * Content ID of the attachment (used for inline attachments)
   */
  contentId: z.string().nullish(),
  /**
   * Url of the attachment
   */
  url: z.string().nullish(),
  /**
   * Size of the attachment in bytes
   */
  size: z.number().int(),
  /**
   * Foreign key referencing the EmailTransaction model
   */
  transactionId: z.string(),
  /**
   * Timestamp of when the record was created
   */
  createdAt: z.date(),
  /**
   * Timestamp of when the record was last updated
   */
  updatedAt: z.date(),
})

export interface CompleteEmailAttachment extends z.infer<typeof EmailAttachmentModel> {
  attachments: CompleteEmailTransaction
}

/**
 * RelatedEmailAttachmentModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedEmailAttachmentModel: z.ZodSchema<CompleteEmailAttachment> = z.lazy(() => EmailAttachmentModel.extend({
  /**
   * Relationship to the EmailTransaction model
   */
  attachments: RelatedEmailTransactionModel,
}))
