import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteMpan, RelatedMpanModel, CompleteSiteMeter, RelatedSiteMeterModel } from "./index"

export const ElectricSiteMeterModel = z.object({
  id: z.string(),
  /**
   * Indicator if the meter has a day rate.
   */
  hasDayRate: z.boolean().nullish(),
  /**
   * Indicator if the meter has a night rate.
   */
  hasNightRate: z.boolean().nullish(),
  /**
   * Indicator if the meter has a evening rate.
   */
  hasWeekendRate: z.boolean().nullish(),
  mpanValue: z.string(),
  siteMeterId: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteElectricSiteMeter extends z.infer<typeof ElectricSiteMeterModel> {
  mpan: CompleteMpan
  siteMeter: CompleteSiteMeter
}

/**
 * RelatedElectricSiteMeterModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedElectricSiteMeterModel: z.ZodSchema<CompleteElectricSiteMeter> = z.lazy(() => ElectricSiteMeterModel.extend({
  mpan: RelatedMpanModel,
  siteMeter: RelatedSiteMeterModel,
}))
