import type { User } from "@supabase/supabase-js";
import { env } from "@watt/common/src/config/env";
import { ProfileModel } from "@watt/db/prisma/zod";
import type { z } from "zod";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

import { createClientComponentClient } from "@/utils/supabase/client";

import { getUserPermissions } from "@/app/utils/role-permissions";
import { isProductionEnvironment } from "@watt/common";
import { log } from "@watt/common/src/utils/axiom-logger";
import { createSelectors } from "./create-selectors";

export { shallow } from "zustand/shallow";

export type UserWithProfileMetaData = {
  user: Omit<User, "user_metadata"> | null;
  profileData: z.infer<typeof ProfileModel> | null;
  permissions: {
    isAuthorised: boolean;
    isAdmin: boolean;
    isSalesAgent: boolean;
    isManager: boolean;
    isAllowedToEditPriceList: boolean;
    isAllowedToViewHiddenOptions: boolean;
    isSystemUser: boolean;
  };
};

export const defaultUserData: UserWithProfileMetaData = {
  user: null,
  profileData: null,
  permissions: {
    isAuthorised: false,
    isAdmin: false,
    isSalesAgent: false,
    isManager: false,
    isAllowedToEditPriceList: false,
    isAllowedToViewHiddenOptions: false,
    isSystemUser: false
  }
};

type State = {
  userData: UserWithProfileMetaData;
};

type Actions = {
  /**
   * Get the user from the client side session, use for low risk actions
   * as this is done on the client side
   * @returns UserWithProfileMetaData | null
   */
  getSessionUser: () => UserWithProfileMetaData;
  setUser: (user: UserWithProfileMetaData) => void;
};

type Store = State & Actions;

export const useAppStore = createSelectors(
  createWithEqualityFn<Store>()(
    persist(
      immer(
        devtools(
          (set, get) => ({
            userData: defaultUserData,
            getSessionUser: () => {
              const userData = get().userData;
              if (userData.user) {
                return userData;
              }

              // TODO (Stephen): Replace this inline logic with the getSupabaseSessionUser() util function
              const supabase = createClientComponentClient();
              supabase.auth
                .getSession()
                .then(({ data: { session } }) => {
                  if (!session) {
                    return defaultUserData;
                  }

                  const { user: supabaseUser } = session;

                  if (!supabaseUser.email) {
                    return defaultUserData;
                  }

                  const {
                    success,
                    error,
                    data: userMetaDataWithProfileData
                  } = ProfileModel.safeParse({
                    ...supabaseUser.user_metadata,
                    email: supabaseUser.email
                  });

                  if (!success) {
                    log.error("hooks/use-authentication.useEffect", {
                      error,
                      userMetaDataWithProfileData
                    });
                    return defaultUserData;
                  }

                  const {
                    isAllowedToEditPriceList,
                    isAllowedToViewHiddenOptions,
                    isAuthorised,
                    isAdmin,
                    isSalesAgent,
                    isManager,
                    isSystemUser
                  } = getUserPermissions(
                    userMetaDataWithProfileData?.role,
                    supabaseUser.email
                  );

                  const userWithProfileData: UserWithProfileMetaData = {
                    user: supabaseUser,
                    profileData: userMetaDataWithProfileData,
                    permissions: {
                      isAuthorised,
                      isAdmin,
                      isSalesAgent,
                      isManager,
                      isAllowedToEditPriceList,
                      isAllowedToViewHiddenOptions,
                      isSystemUser
                    }
                  };

                  set({
                    userData: {
                      ...userWithProfileData,
                      // To support testing this user will mimic failure to fetch profile data
                      profileData:
                        userWithProfileData.profileData?.email ===
                          "finn.hatzar@watt.co.uk" && !isProductionEnvironment()
                          ? null
                          : userWithProfileData.profileData
                    }
                  });

                  return userWithProfileData;
                })
                .catch(error => {
                  log.error("hooks/use-authentication.useEffect", {
                    error
                  });
                  return defaultUserData;
                });
              return defaultUserData;
            },
            setUser: userData => {
              set({ userData });
            }
          }),
          {
            enabled:
              env.NODE_ENV === "development" && typeof window !== "undefined",
            name: "App Store"
          }
        )
      ),
      {
        name: "App Store",
        /**
         * If the client component pre-renders on the server and makes use of this store then the persist middleware will not
         * work on the server as it uses localStorage. We must skip hydration and use
         *
         * Example:
         *
         * ```tsx
         * useEffect(() => {
         *   useAppStore.persist.rehydrate();
         * }, []);
         * ```
         * in the client component to hydrate the store. To avoid hydration errors we must skip using the persist middleware
         * when rendering on the server. and then use rehydrate() in the client component from a useEffect as useEffects
         * are not called on the server.
         */
        skipHydration: true
      }
    ),
    shallow
  )
);
