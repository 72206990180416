import * as z from "zod"
import * as imports from "../zod-schemas"

export const CompaniesHouseModel = z.object({
  /**
   * Unique identifier for companies house company
   */
  id: z.string(),
  /**
   * Name of the company
   */
  companyName: z.string().nullish(),
  /**
   * Unique company registration number
   */
  companyNumber: z.string().nullish(),
  /**
   * Care of address for company registration
   */
  regAddressCareOf: z.string().nullish(),
  /**
   * Post office box number for company registration
   */
  regAddressPOBox: z.string().nullish(),
  /**
   * First line of the registered company address
   */
  regAddressAddressLine1: z.string().nullish(),
  /**
   * Second line of the registered company address
   */
  regAddressAddressLine2: z.string().nullish(),
  /**
   * Post town of the registered company address
   */
  regAddressPostTown: z.string().nullish(),
  /**
   * County of the registered company address
   */
  regAddressCounty: z.string().nullish(),
  /**
   * Country of the registered company address
   */
  regAddressCountry: z.string().nullish(),
  /**
   * Postal code of the registered company address
   */
  regAddressPostCode: z.string().nullish(),
  /**
   * Category or type of the company
   */
  companyCategory: z.string().nullish(),
  /**
   * Current status of the company
   */
  companyStatus: z.string().nullish(),
  /**
   * Country of origin for the company
   */
  countryOfOrigin: z.string().nullish(),
  /**
   * Date of company dissolution, if applicable
   */
  dissolutionDate: z.date().nullish(),
  /**
   * Date of company incorporation
   */
  incorporationDate: z.date().nullish(),
  /**
   * Reference day for company accounts
   */
  accountsAccountRefDay: z.number().int().nullish(),
  /**
   * Reference month for company accounts
   */
  accountsAccountRefMonth: z.number().int().nullish(),
  /**
   * Next due date for company accounts
   */
  accountsNextDueDate: z.date().nullish(),
  /**
   * Last made-up date for company accounts
   */
  accountsLastMadeUpDate: z.date().nullish(),
  /**
   * Category of company accounts
   */
  accountsAccountCategory: z.string().nullish(),
  /**
   * Next due date for company returns
   */
  returnsNextDueDate: z.date().nullish(),
  /**
   * Last made-up date for company returns
   */
  returnsLastMadeUpDate: z.date().nullish(),
  /**
   * Number of mortgage charges
   */
  mortgagesNumMortCharges: z.number().int().nullish(),
  /**
   * Number of outstanding mortgages
   */
  mortgagesNumMortOutstanding: z.number().int().nullish(),
  /**
   * Number of partially satisfied mortgages
   */
  mortgagesNumMortPartSatisfied: z.number().int().nullish(),
  /**
   * Number of satisfied mortgages
   */
  mortgagesNumMortSatisfied: z.number().int().nullish(),
  /**
   * Primary Standard Industrial Classification (SIC) text
   */
  sicCodeSicText1: z.string().nullish(),
  /**
   * Secondary Standard Industrial Classification (SIC) text
   */
  sicCodeSicText2: z.string().nullish(),
  /**
   * Tertiary Standard Industrial Classification (SIC) text
   */
  sicCodeSicText3: z.string().nullish(),
  /**
   * Quaternary Standard Industrial Classification (SIC) text
   */
  sicCodeSicText4: z.string().nullish(),
  /**
   * Number of general partners in limited partnerships
   */
  limitedPartnershipsNumGenPartners: z.number().int().nullish(),
  /**
   * Number of limited partners in limited partnerships
   */
  limitedPartnershipsNumLimPartners: z.number().int().nullish(),
  /**
   * Uniform Resource Identifier (URI) for company data
   */
  uri: z.string().nullish(),
  /**
   * Date of first previous company name change
   */
  previousName1ConDate: z.date().nullish(),
  /**
   * First previous company name
   */
  previousName1CompanyName: z.string().nullish(),
  /**
   * Date of second previous company name change
   */
  previousName2ConDate: z.date().nullish(),
  /**
   * Second previous company name
   */
  previousName2CompanyName: z.string().nullish(),
  /**
   * Date of third previous company name change
   */
  previousName3ConDate: z.date().nullish(),
  /**
   * Third previous company name
   */
  previousName3CompanyName: z.string().nullish(),
  /**
   * Date of fourth previous company name change
   */
  previousName4ConDate: z.date().nullish(),
  /**
   * Fourth previous company name
   */
  previousName4CompanyName: z.string().nullish(),
  /**
   * Date of fifth previous company name change
   */
  previousName5ConDate: z.date().nullish(),
  /**
   * Fifth previous company name
   */
  previousName5CompanyName: z.string().nullish(),
  /**
   * Date of sixth previous company name change
   */
  previousName6ConDate: z.date().nullish(),
  /**
   * Sixth previous company name
   */
  previousName6CompanyName: z.string().nullish(),
  /**
   * Date of seventh previous company name change
   */
  previousName7ConDate: z.date().nullish(),
  /**
   * Seventh previous company name
   */
  previousName7CompanyName: z.string().nullish(),
  /**
   * Date of eighth previous company name change
   */
  previousName8ConDate: z.date().nullish(),
  /**
   * Eighth previous company name
   */
  previousName8CompanyName: z.string().nullish(),
  /**
   * Date of ninth previous company name change
   */
  previousName9ConDate: z.date().nullish(),
  /**
   * Ninth previous company name
   */
  previousName9CompanyName: z.string().nullish(),
  /**
   * Date of tenth previous company name change
   */
  previousName10ConDate: z.date().nullish(),
  /**
   * Tenth previous company name
   */
  previousName10CompanyName: z.string().nullish(),
  /**
   * Next due date for confirmation statement
   */
  confStmtNextDueDate: z.date().nullish(),
  /**
   * Last made-up date for confirmation statement
   */
  confStmtLastMadeUpDate: z.date().nullish(),
})
