import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteMpan, RelatedMpanModel, CompleteMeteringPointAdditionalDetail, RelatedMeteringPointAdditionalDetailModel, CompleteMeteringPointTPRData, RelatedMeteringPointTPRDataModel } from "./index"

export const ElectricMeteringPointModel = z.object({
  /**
   * Unique identifier for the metering point.
   */
  id: z.string(),
  /**
   * Unique identifier for the metering point.
   */
  mpanId: z.string(),
  /**
   * Unique Property Reference Number.
   */
  uprn: z.string().nullish(),
  /**
   * Unique Delivery Point Reference Number.
   */
  udprn: z.string().nullish(),
  /**
   * First line of the physical address associated with the metering point.
   */
  addressLine1: z.string().nullish(),
  /**
   * Second line of the physical address associated with the metering point.
   */
  addressLine2: z.string().nullish(),
  /**
   * Third line of the physical address associated with the metering point.
   */
  addressLine3: z.string(),
  /**
   * Fourth line of the physical address associated with the metering point.
   */
  addressLine4: z.string().nullish(),
  /**
   * Fifth line of the physical address associated with the metering point.
   */
  addressLine5: z.string(),
  /**
   * Sixth line of the physical address associated with the metering point.
   */
  addressLine6: z.string().nullish(),
  /**
   * Seventh line of the physical address associated with the metering point.
   */
  addressLine7: z.string().nullish(),
  /**
   * Eighth line of the physical address associated with the metering point.
   */
  addressLine8: z.string(),
  /**
   * Ninth line of the physical address associated with the metering point.
   */
  addressLine9: z.string(),
  /**
   * Postal code of the metering point's physical address.
   */
  postCode: z.string(),
  /**
   * Identifier for the electricity supplier at this MPAN.
   */
  supplierId: z.string(),
  /**
   * Name of the electricity supplier.
   */
  supplierName: z.string().nullish(),
  /**
   * Effective From Date for when the current supplier information becomes valid.
   */
  supplierEfd: z.date(),
  /**
   * Estimated Annual Consumption, the estimated total electricity consumed over a year (kWh).
   */
  totalEac: z.number().nullish(),
  /**
   * Effective From Date for the EAC value.
   */
  eacEfd: z.date(),
  /**
   * A Boolean flag that could represent electric tariff related information (specific to your system).
   */
  et: z.boolean(),
  /**
   * Reference number identifying the settlement configuration for this MPAN.
   */
  standardSettlementConfigurationId: z.string().nullish(),
  /**
   * Effective From Date for the standard settlement configuration.
   */
  standardSettlementConfigurationEfd: z.date().nullish(),
  /**
   * Identifier for the class used to account for electricity loss in transmission.
   */
  lineLossFactorClassId: z.string().nullish(),
  /**
   * Effective From Date for the Line Loss Factor Class ID.
   */
  lineLossFactorClassEfd: z.date().nullish(),
  /**
   * Indicates the status of the supply point, e.g., 'E' for energised (connected) or 'D' for de-energised (disconnected).
   */
  energisationStatus: z.string().nullish(),
  /**
   * Effective From Date for the energisation status.
   */
  energisationStatusEfd: z.date().nullish(),
  /**
   * The agreed capacity for electricity supply at that metering point, in kilowatts (kW).
   */
  capacity: z.string().nullish(),
  /**
   * Indicates if the MPAN has been disconnected from the network.
   */
  disconnectedMpan: z.string().nullish(),
  /**
   * The date when the metering point was disconnected from the network.
   */
  disconnectionDate: z.date().nullish(),
  /**
   * The distributor market Participant ID.
   */
  distributorMpid: z.string().nullish(),
  /**
   * Effective From Date for the distributor mpid.
   */
  distributorEfd: z.date().nullish(),
  /**
   * A flag indicating if the metering point is part of the DCC (Data Communications Company) service.
   */
  dccServiceFlag: z.string().nullish(),
  /**
   * Effective From Date for the DCC service flag.
   */
  dccServiceFlagEfd: z.date().nullish(),
  /**
   * Status of the In-Home Display (IHD) for smart meters.
   */
  ihdStatus: z.string().nullish(),
  /**
   * Effective From Date for the In-Home Display (IHD) status.
   */
  ihdStatusEfd: z.date().nullish(),
  /**
   * The version of the Smart Metering Equipment Technical Specifications (SMETS) for this metering point.
   */
  smetsVersion: z.string().nullish(),
  /**
   * Indicates whether the metering point is operating, trading status.
   */
  tradingStatus: z.string().nullish(),
  /**
   * Effective From Date for the trading status.
   */
  tradingStatusEfd: z.date().nullish(),
  /**
   * Type of consumer at the metering point, e.g., "Non-Domestic".
   */
  consumerType: z.string().nullish(),
  /**
   * Indicator of the relationship status.
   */
  relationshipStatusIndicator: z.string().nullish(),
  /**
   * State of the Relationship Management Process (RMP).
   */
  rmpState: z.string().nullish(),
  /**
   * Effective From Date for the RMP state.
   */
  rmpEfd: z.date().nullish(),
  /**
   * Indicator if the consumer is a domestic consumer.
   */
  domesticConsumerIndicator: z.string().nullish(),
  /**
   * CSS (Central Switching Service) Supplier ID.
   */
  cssSupplierMpid: z.string().nullish(),
  /**
   * Start date of the supply from CSS Supplier.
   */
  cssSupplyStartDate: z.date().nullish(),
  /**
   * Metering point access provider identifier.
   */
  mapMpid: z.string().nullish(),
  /**
   * Effective From Date for the MAP (Metering point access provider) ID.
   */
  mapMpidEfd: z.date().nullish(),
  /**
   * Identifier for the supplier responsible for installing the meter.
   */
  installingSupplierMpid: z.string().nullish(),
  /**
   * Identifier for the Data Aggregator (party responsible for data aggregation).
   */
  dataAggregatorMpid: z.string().nullish(),
  /**
   * Effective From Date for when the data aggregator ID becomes valid.
   */
  dataAggregatorEfd: z.date().nullish(),
  /**
   * Identifier for the Data Collector (party responsible for meter data collection).
   */
  dataCollectorMpid: z.string().nullish(),
  /**
   * Effective From Date for when the data collector ID becomes valid.
   */
  dataCollectorEfd: z.date().nullish(),
  /**
   * Identifier for the Meter Operator (party responsible for meter operation).
   */
  meterOperatorMpid: z.string().nullish(),
  /**
   * Effective From Date for when the meter operator ID becomes valid.
   */
  meterOperatorEfd: z.date().nullish(),
  /**
   * Flag indicating the effectivity of Green Deal (energy efficiency scheme) on the metering point.
   */
  greenDealInEffect: z.string().nullish(),
  /**
   * Identifier for the Meter Operator responsible for smart meters within the Smart Metering System.
   */
  smsoMpid: z.string().nullish(),
  /**
   * Effective From Date for when the SMOP ID becomes valid.
   */
  smsoEfd: z.date().nullish(),
  /**
   * Indicator of whether the metering point is considered to have a meter based on the meter point administration system.
   */
  meteredIndicator: z.string().nullish(),
  /**
   * Effective From Date for when the metered indicator becomes valid.
   */
  meteredIndicatorEfd: z.date().nullish(),
  /**
   * Effective To Date for when the metered indicator ceases to be valid.
   */
  meteredIndicatorEtd: z.date().nullish(),
  /**
   * Profile Class Effective From Date.
   */
  profileClassEfd: z.date().nullish(),
  /**
   * Meter Timeswitch Class Effective From Date.
   */
  mtcEfd: z.date().nullish(),
  /**
   * GSP Group Effective From Date.
   */
  gspGroupEfd: z.date().nullish(),
  /**
   * Measurement Class Effective From Date.
   */
  measurementClassEfd: z.date().nullish(),
  /**
   * Timestamp when the metering point record was created.
   */
  createdAt: z.date(),
  /**
   * Timestamp when the metering point record was last updated.
   */
  updatedAt: z.date(),
})

export interface CompleteElectricMeteringPoint extends z.infer<typeof ElectricMeteringPointModel> {
  mpan: CompleteMpan
  additionalDetails: CompleteMeteringPointAdditionalDetail[]
  tprData: CompleteMeteringPointTPRData[]
}

/**
 * RelatedElectricMeteringPointModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedElectricMeteringPointModel: z.ZodSchema<CompleteElectricMeteringPoint> = z.lazy(() => ElectricMeteringPointModel.extend({
  /**
   * Meter Point Administration Number, unique identifier for every electricity metering point in the UK.
   */
  mpan: RelatedMpanModel,
  /**
   * Related model capturing additional detailed entries.
   */
  additionalDetails: RelatedMeteringPointAdditionalDetailModel.array(),
  /**
   * Related model capturing Time Pattern Regime data entries.
   */
  tprData: RelatedMeteringPointTPRDataModel.array(),
}))
