import * as z from "zod"
import * as imports from "../zod-schemas"
import { AbandonCallStatus, CallStatus } from "@prisma/client"

export const AbandonCallInstanceModel = z.object({
  /**
   * The unique identifier for this Call resource that was abandoned.
   */
  callSid: z.string(),
  status: z.nativeEnum(AbandonCallStatus),
  queueResult: z.string().nullish(),
  /**
   * The phone number that received this call.
   */
  called: z.string().nullish(),
  /**
   * The state the call was received in.
   */
  toState: z.string().nullish(),
  /**
   * The country code of the caller.
   */
  callerCountry: z.string().nullish(),
  /**
   * The direction of the call (inbound or outbound).
   */
  direction: z.string(),
  /**
   * The time the call was in the queue (in seconds).
   */
  queueTime: z.string().nullish(),
  /**
   * The phone number that this call was made to.
   */
  to: z.string(),
  /**
   * The country code where the call was received.
   */
  toCountry: z.string().nullish(),
  /**
   * The API version used for this call.
   */
  apiVersion: z.string(),
  /**
   * The current status of the call.
   */
  callStatus: z.nativeEnum(CallStatus),
  /**
   * The phone number, SIP address or Client identifier that made this call.
   */
  from: z.string(),
  /**
   * The unique identifier for the queue.
   */
  queueSid: z.string().nullish(),
  /**
   * The unique identifier for the account.
   */
  accountSid: z.string(),
  /**
   * The country code where the call was made to.
   */
  calledCountry: z.string().nullish(),
  /**
   * The country code of the call origin.
   */
  fromCountry: z.string().nullish(),
  /**
   * The phone number that made the call.
   */
  caller: z.string().nullish(),
  /**
   * The state the call was made from.
   */
  calledState: z.string().nullish(),
  /**
   * Timestamp when this record was created.
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when this record was last updated.
   */
  updatedAt: z.date().optional(),
})
