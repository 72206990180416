import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteBusinessTargeter, RelatedBusinessTargeterModel } from "./index"

export const BusinessTargetSearchSummaryModel = z.object({
  id: z.string(),
  /**
   * Number of matches returned from the Business Targeter search
   */
  numberMatches: z.number().int(),
  /**
   * Business reference returned from the Business Targeter search
   */
  businessRef: z.string(),
  /**
   * Business name returned from the Business Targeter search
   */
  businessName: z.string(),
  /**
   * Business telephone number returned from the Business Targeter search
   */
  phoneNumber: z.string(),
  /**
   * Business post town returned from the Business Targeter search
   */
  town: z.string(),
  /**
   * Business post code returned from the Business Targeter search
   */
  postcode: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteBusinessTargetSearchSummary extends z.infer<typeof BusinessTargetSearchSummaryModel> {
  businessTargets: CompleteBusinessTargeter[]
}

/**
 * RelatedBusinessTargetSearchSummaryModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedBusinessTargetSearchSummaryModel: z.ZodSchema<CompleteBusinessTargetSearchSummary> = z.lazy(() => BusinessTargetSearchSummaryModel.extend({
  /**
   * Establish the many-to-many relation with BusinessTargeter
   */
  businessTargets: RelatedBusinessTargeterModel.array(),
}))
