import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteCompany, RelatedCompanyModel } from "./index"

export const LOAModel = z.object({
  id: z.string().optional(),
  companyName: z.string().min(1, "Please enter company name"),
  contactForename: z.string().nonempty(),
  contactSurname: z.string().nonempty(),
  contactEmail: z.string().email("Invalid email address"),
  contactPosition: z.string().min(1, "Please enter contact position"),
  companyEntityAddress: z.string().min(1, "Please enter a valid company address"),
  meterNumber: z.string().optional().nullish(),
  loaValidPeriod: z.number().int(),
  signedDate: z.date().optional().nullish(),
  startDate: z.date(),
  expiryDate: z.date(),
  companyId: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteLOA extends z.infer<typeof LOAModel> {
  company: CompleteCompany
}

/**
 * RelatedLOAModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedLOAModel: z.ZodSchema<CompleteLOA> = z.lazy(() => LOAModel.extend({
  company: RelatedCompanyModel,
}))
