import * as z from "zod"
import * as imports from "../zod-schemas"
import { AgentStatus } from "@prisma/client"
import { CompleteProfile, RelatedProfileModel } from "./index"

export const AgentActivityLogModel = z.object({
  /**
   * The unique identifier for each activity entry.
   */
  id: z.string().optional(),
  /**
   * The name of the agent.
   */
  agentName: z.string(),
  /**
   * Current status of the agent
   */
  agentStatus: z.nativeEnum(AgentStatus),
  /**
   * The phone number the agent interacted with.
   */
  phoneNumber: z.string().nullish(),
  /**
   * Indicates whether the call was not answered and was abandoned.
   */
  callAbandoned: z.boolean().nullish(),
  /**
   * The duration of the call in seconds.
   */
  duration: z.number().int(),
  /**
   * The PBX used. Default to 'Head Office'.
   */
  pbx: z.string().nullish(),
  createdById: z.string(),
  /**
   * Timestamp when this record was created.
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when this record was last updated.
   */
  updatedAt: z.date().optional(),
})

export interface CompleteAgentActivityLog extends z.infer<typeof AgentActivityLogModel> {
  createdBy: CompleteProfile
}

/**
 * RelatedAgentActivityLogModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedAgentActivityLogModel: z.ZodSchema<CompleteAgentActivityLog> = z.lazy(() => AgentActivityLogModel.extend({
  createdBy: RelatedProfileModel,
}))
