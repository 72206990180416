import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteEmailTransaction, RelatedEmailTransactionModel, CompleteProfile, RelatedProfileModel } from "./index"

export const CommunicationInteractionModel = z.object({
  /**
   * Unique identifier for the email communication
   */
  id: z.string().optional(),
  /**
   * Unique message ID assigned by the email service provider
   */
  messageId: z.string(),
  /**
   * Name of the sender or recipient
   */
  name: z.string(),
  /**
   * Indicates whether the email has been read
   */
  read: z.boolean(),
  /**
   * Labels associated with the email (handled in application logic, stored as a string representation of an array)
   */
  labels: z.string().array().optional(),
  /**
   * Timestamp of when the record was created
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp of when the record was last updated
   */
  updatedAt: z.date().optional(),
  emailTransactionId: z.string(),
  /**
   * Agent who initiated the communication
   */
  createdById: z.string(),
})

export interface CompleteCommunicationInteraction extends z.infer<typeof CommunicationInteractionModel> {
  emailTransaction: CompleteEmailTransaction
  createdBy: CompleteProfile
}

/**
 * RelatedCommunicationInteractionModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCommunicationInteractionModel: z.ZodSchema<CompleteCommunicationInteraction> = z.lazy(() => CommunicationInteractionModel.extend({
  emailTransaction: RelatedEmailTransactionModel,
  createdBy: RelatedProfileModel,
}))
