import * as z from "zod"
import * as imports from "../zod-schemas"
import { EmailTransactionEvents } from "@prisma/client"
import { CompleteProfile, RelatedProfileModel, CompleteCommunicationInteraction, RelatedCommunicationInteractionModel, CompleteEmailEvent, RelatedEmailEventModel, CompleteEmailQuote, RelatedEmailQuoteModel, CompleteEmailAttachment, RelatedEmailAttachmentModel } from "./index"

export const EmailTransactionModel = z.object({
  /**
   * Unique identifier for the email transaction
   */
  id: z.string().optional(),
  /**
   * Unique message ID assigned by the email service provider
   */
  messageId: z.string(),
  /**
   * Email address of the sender
   */
  from: z.string(),
  /**
   * Email address used for replies
   */
  replyTo: z.string(),
  /**
   * Email address of the recipient
   */
  to: z.string(),
  /**
   * Subject of the email
   */
  subject: z.string(),
  /**
   * HTML content of the email
   */
  htmlContent: z.string(),
  /**
   * Text content of the email
   */
  textContent: z.string(),
  /**
   * Status code returned by the email service provider
   */
  statusCode: z.number().int(),
  /**
   * Date and time when the email transaction was initiated
   */
  dateSent: z.date(),
  /**
   * the event type (constraint enforcement and specific values to be handled in application logic)
   */
  event: z.nativeEnum(EmailTransactionEvents).nullish(),
  /**
   * Timestamp of when the record was created
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp of when the record was last updated
   */
  updatedAt: z.date().optional(),
  createdById: z.string(),
})

export interface CompleteEmailTransaction extends z.infer<typeof EmailTransactionModel> {
  createdBy: CompleteProfile
  communicationInteraction?: CompleteCommunicationInteraction | null
  emailEvents: CompleteEmailEvent[]
  emailQuotes: CompleteEmailQuote[]
  attachments: CompleteEmailAttachment[]
}

/**
 * RelatedEmailTransactionModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedEmailTransactionModel: z.ZodSchema<CompleteEmailTransaction> = z.lazy(() => EmailTransactionModel.extend({
  createdBy: RelatedProfileModel,
  communicationInteraction: RelatedCommunicationInteractionModel.nullish(),
  emailEvents: RelatedEmailEventModel.array(),
  emailQuotes: RelatedEmailQuoteModel.array(),
  attachments: RelatedEmailAttachmentModel.array(),
}))
