import * as z from "zod"
import * as imports from "../zod-schemas"
import { UtilityType, ContractType, ContractStatus } from "@prisma/client"
import { CompleteCompany, RelatedCompanyModel, CompleteCompanySite, RelatedCompanySiteModel, CompleteSiteMeter, RelatedSiteMeterModel, CompleteQuote, RelatedQuoteModel, CompleteVerbalContract, RelatedVerbalContractModel, CompleteDeal, RelatedDealModel, CompleteProfile, RelatedProfileModel } from "./index"

export const ContractModel = z.object({
  id: z.string(),
  utilityType: z.nativeEnum(UtilityType),
  type: z.nativeEnum(ContractType),
  status: z.nativeEnum(ContractStatus),
  startDate: z.date(),
  endDate: z.date(),
  term: z.number().int(),
  commission: z.number(),
  companyId: z.string(),
  siteId: z.string(),
  siteMeterId: z.string().nullish(),
  quoteId: z.string(),
  createdById: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteContract extends z.infer<typeof ContractModel> {
  company: CompleteCompany
  site: CompleteCompanySite
  siteMeter?: CompleteSiteMeter | null
  quote: CompleteQuote
  verbalContract?: CompleteVerbalContract | null
  deal?: CompleteDeal | null
  createdBy: CompleteProfile
}

/**
 * RelatedContractModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedContractModel: z.ZodSchema<CompleteContract> = z.lazy(() => ContractModel.extend({
  company: RelatedCompanyModel,
  site: RelatedCompanySiteModel,
  siteMeter: RelatedSiteMeterModel.nullish(),
  quote: RelatedQuoteModel,
  verbalContract: RelatedVerbalContractModel.nullish(),
  deal: RelatedDealModel.nullish(),
  createdBy: RelatedProfileModel,
}))
