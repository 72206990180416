import { z } from "zod";

export const CommercialDelphiScoreHistorySchema = z.array(
  z.object({
    scoreHistoryDate: z.string(),
    overrideIndicator: z.string(),
    score: z.number(),
    creditRatingOverrideIndicator: z.string(),
    creditRating: z.number(),
    creditLimitOverrideIndicator: z.string(),
    creditLimit: z.number()
  })
);

export const ConvertNumberToString = z
  .union([z.string(), z.number()])
  .transform(value => {
    if (typeof value === "number") {
      return value.toLocaleString();
    }
    return value;
  });
