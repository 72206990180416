import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteMpan, RelatedMpanModel, CompleteQuoteList, RelatedQuoteListModel } from "./index"

export const ElectricityUsageModel = z.object({
  /**
   * Unique identifier for the utility usage
   */
  id: z.string(),
  manualConsumptionEntry: z.boolean(),
  tprTariffCount: z.number().int(),
  totalUsage: z.number(),
  dayUsage: z.number(),
  nightUsage: z.number(),
  weekendUsage: z.number(),
  /**
   * The capacity figure allocated to the site meter measured in kVA
   */
  capacityFigureKva: z.number().int().nullish(),
  mpanValue: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteElectricityUsage extends z.infer<typeof ElectricityUsageModel> {
  mpan: CompleteMpan
  quoteList: CompleteQuoteList[]
}

/**
 * RelatedElectricityUsageModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedElectricityUsageModel: z.ZodSchema<CompleteElectricityUsage> = z.lazy(() => ElectricityUsageModel.extend({
  mpan: RelatedMpanModel,
  quoteList: RelatedQuoteListModel.array(),
}))
