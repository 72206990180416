import {
  QueryClient,
  defaultShouldDehydrateQuery
} from "@tanstack/react-query";
import SuperJSON from "superjson";

export const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        // https://www.codemzy.com/blog/react-query-cachetime-staletime
        staleTime: 30 * 1000 // defaults to zero
      },
      dehydrate: {
        serializeData: SuperJSON.serialize,
        shouldDehydrateQuery: query =>
          defaultShouldDehydrateQuery(query) || query.state.status === "pending"
      },
      hydrate: {
        deserializeData: SuperJSON.deserialize
      }
    }
  });
