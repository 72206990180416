import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteCompanyContact, RelatedCompanyContactModel, CompleteProfile, RelatedProfileModel } from "./index"

export const CompanyContactEmailModel = z.object({
  id: z.string(),
  /**
   * The email address for the company contact which could be a shared email and so can't be uniquly constrained
   */
  email: z.string(),
  isPrimary: z.boolean(),
  contactId: z.string(),
  createdById: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteCompanyContactEmail extends z.infer<typeof CompanyContactEmailModel> {
  contact: CompleteCompanyContact
  createdBy: CompleteProfile
}

/**
 * RelatedCompanyContactEmailModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCompanyContactEmailModel: z.ZodSchema<CompleteCompanyContactEmail> = z.lazy(() => CompanyContactEmailModel.extend({
  contact: RelatedCompanyContactModel,
  createdBy: RelatedProfileModel,
}))
