import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteElectricMeteringPoint, RelatedElectricMeteringPointModel } from "./index"

export const MeteringPointTPRDataModel = z.object({
  /**
   * Unique identifier for the TPR data entry.
   */
  id: z.string(),
  /**
   * Foreign key connecting back to the MeteringPoint model.
   */
  meteringPointId: z.string(),
  /**
   * Estimated Annual Consumption (EAC) for a specific Time Pattern Regime (TPR).
   */
  tprEac: z.number(),
  /**
   * Identifier for the Time Pattern Regime, segmenting the day for different usage rates.
   */
  eacTpr: z.string(),
  /**
   * Effective From Date for the TPR's Estimated Annual Consumption.
   */
  eacEfd: z.date(),
  /**
   * Timestamp when the TPR data record was created.
   */
  createdAt: z.date(),
  /**
   * Timestamp when the TPR data record was last updated.
   */
  updatedAt: z.date(),
})

export interface CompleteMeteringPointTPRData extends z.infer<typeof MeteringPointTPRDataModel> {
  meteringPoint: CompleteElectricMeteringPoint
}

/**
 * RelatedMeteringPointTPRDataModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedMeteringPointTPRDataModel: z.ZodSchema<CompleteMeteringPointTPRData> = z.lazy(() => MeteringPointTPRDataModel.extend({
  /**
   * Relation to the MeteringPoint model that this TPR data is associated with.
   */
  meteringPoint: RelatedElectricMeteringPointModel,
}))
