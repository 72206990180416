import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteEntityAddress, RelatedEntityAddressModel, CompleteElectricityUsage, RelatedElectricityUsageModel, CompleteElectricitySupplier, RelatedElectricitySupplierModel, CompleteElectricSiteMeter, RelatedElectricSiteMeterModel, CompleteElectricMeteringPoint, RelatedElectricMeteringPointModel } from "./index"

export const MpanModel = z.object({
  /**
   * The unique identifier for this MPAN resource.
   */
  id: z.string(),
  /**
   * Unique Property Reference Number.
   */
  uprn: z.string().nullish(),
  /**
   * Unique Delivery Point Reference Number.
   */
  udprn: z.string().nullish(),
  /**
   * The Meter Point Access Number value.
   */
  value: z.string(),
  /**
   * Profile class categorising the expected electricity usage pattern.
   */
  profileClass: z.string().nullish(),
  /**
   * Meter Timeswitch Code, indicating the type of metering in terms of the time of day rate switches.
   */
  mtc: z.string().nullish(),
  /**
   * Identifier for the class used to account for electricity loss in transmission.
   */
  lineLossFactor: z.string().nullish(),
  /**
   * Reference number identifying the settlement configuration for this MPAN.
   */
  standardSettlementConfigurationId: z.string().nullish(),
  /**
   * / Type of electricity meter installed. "H" for half-hourly. TODO: Checkout electralink docs for more info.
   */
  meterType: z.string().nullish(),
  /**
   * Class indicating the precision of measurement for energy consumption.
   */
  measurementClass: z.string().nullish(),
  entityAddressId: z.string(),
  /**
   * ID of the user who created this record. No FK constraint required atm due to no or low query volume
   */
  createdById: z.string().nullish(),
  /**
   * ID of the user who last updated this record. No FK constraint required atm due to no or low query volume
   */
  updatedById: z.string().nullish(),
  /**
   * Timestamp when this record was created.
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when this record was last updated.
   */
  updatedAt: z.date().optional(),
})

export interface CompleteMpan extends z.infer<typeof MpanModel> {
  entityAddress: CompleteEntityAddress
  electricityUsage: CompleteElectricityUsage[]
  electricitySupplier: CompleteElectricitySupplier[]
  electricSiteMeter: CompleteElectricSiteMeter[]
  electricMeteringPoints: CompleteElectricMeteringPoint[]
}

/**
 * RelatedMpanModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedMpanModel: z.ZodSchema<CompleteMpan> = z.lazy(() => MpanModel.extend({
  entityAddress: RelatedEntityAddressModel,
  electricityUsage: RelatedElectricityUsageModel.array(),
  electricitySupplier: RelatedElectricitySupplierModel.array(),
  electricSiteMeter: RelatedElectricSiteMeterModel.array(),
  electricMeteringPoints: RelatedElectricMeteringPointModel.array(),
}))
