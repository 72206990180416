import * as z from "zod"
import * as imports from "../zod-schemas"
import { UtilityType } from "@prisma/client"
import { CompleteCompanySite, RelatedCompanySiteModel, CompleteElectricSiteMeter, RelatedElectricSiteMeterModel, CompleteGasSiteMeter, RelatedGasSiteMeterModel, CompleteQuoteList, RelatedQuoteListModel, CompleteContract, RelatedContractModel, CompleteDeal, RelatedDealModel, CompleteNote, RelatedNoteModel } from "./index"

export const SiteMeterModel = z.object({
  /**
   * Unique identifier for the site meter
   */
  id: z.string(),
  /**
   * Type of utility associated with this meter (e.g., electricity, gas, water)
   */
  utilityType: z.nativeEnum(UtilityType),
  /**
   * Indicates whether the meter is a smart meter
   */
  isSmartMeter: z.boolean(),
  /**
   * Indicates whether the meter is de-energised (not in use)
   */
  isDeEnergised: z.boolean(),
  /**
   * Unique identifier for the meter itself (e.g. mpan, mprn or spid)
   */
  isLinkedToExistingMeter: z.boolean(),
  /**
   * ID of the company site this meter is associated with
   */
  companySiteId: z.string(),
  /**
   * Timestamp of when the record was created
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp of when the record was last updated
   */
  updatedAt: z.date().optional(),
})

export interface CompleteSiteMeter extends z.infer<typeof SiteMeterModel> {
  companySite: CompleteCompanySite
  electricSiteMeter?: CompleteElectricSiteMeter | null
  gasSiteMeter?: CompleteGasSiteMeter | null
  quoteList: CompleteQuoteList[]
  contracts: CompleteContract[]
  deals: CompleteDeal[]
  notes: CompleteNote[]
}

/**
 * RelatedSiteMeterModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedSiteMeterModel: z.ZodSchema<CompleteSiteMeter> = z.lazy(() => SiteMeterModel.extend({
  /**
   * Relation to the company site
   */
  companySite: RelatedCompanySiteModel,
  /**
   * Optional relation to electric-specific meter details
   */
  electricSiteMeter: RelatedElectricSiteMeterModel.nullish(),
  /**
   * Optional relation to gas-specific meter details
   */
  gasSiteMeter: RelatedGasSiteMeterModel.nullish(),
  /**
   * List of quote lists associated with this meter
   */
  quoteList: RelatedQuoteListModel.array(),
  /**
   * List of contracts associated with this meter
   */
  contracts: RelatedContractModel.array(),
  /**
   * List of deals associated with this meter
   */
  deals: RelatedDealModel.array(),
  /**
   * List of notes associated with this meter
   */
  notes: RelatedNoteModel.array(),
}))
