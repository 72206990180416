import * as z from "zod"
import * as imports from "../zod-schemas"
import { BusinessType } from "@prisma/client"
import { CompleteEntityAddress, RelatedEntityAddressModel, CompleteBusinessTargeter, RelatedBusinessTargeterModel, CompleteCompanySite, RelatedCompanySiteModel, CompleteCreditScore, RelatedCreditScoreModel, CompleteCompanyDetail, RelatedCompanyDetailModel, CompleteContract, RelatedContractModel, CompleteDeal, RelatedDealModel, CompleteCompanyContact, RelatedCompanyContactModel, CompleteActivityLog, RelatedActivityLogModel, CompleteNote, RelatedNoteModel, CompleteLOA, RelatedLOAModel } from "./index"

export const CompanyModel = z.object({
  /**
   * Unique identifier for the company
   */
  id: z.string(),
  /**
   * Type of the company
   */
  businessType: z.nativeEnum(BusinessType),
  /**
   * Name of the company
   */
  name: z.string(),
  /**
   * Registration number of the business entity
   */
  registrationNumber: z.string(),
  entityAddressId: z.string(),
  businessTargeterBusinessRef: z.string().nullish(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteCompany extends z.infer<typeof CompanyModel> {
  entityAddress: CompleteEntityAddress
  businessTarget?: CompleteBusinessTargeter | null
  sites: CompleteCompanySite[]
  creditScore: CompleteCreditScore[]
  companyDetails?: CompleteCompanyDetail | null
  contracts: CompleteContract[]
  deals: CompleteDeal[]
  contacts: CompleteCompanyContact[]
  activities: CompleteActivityLog[]
  notes: CompleteNote[]
  loas: CompleteLOA[]
}

/**
 * RelatedCompanyModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCompanyModel: z.ZodSchema<CompleteCompany> = z.lazy(() => CompanyModel.extend({
  entityAddress: RelatedEntityAddressModel,
  businessTarget: RelatedBusinessTargeterModel.nullish(),
  sites: RelatedCompanySiteModel.array(),
  creditScore: RelatedCreditScoreModel.array(),
  companyDetails: RelatedCompanyDetailModel.nullish(),
  contracts: RelatedContractModel.array(),
  deals: RelatedDealModel.array(),
  contacts: RelatedCompanyContactModel.array(),
  activities: RelatedActivityLogModel.array(),
  notes: RelatedNoteModel.array(),
  loas: RelatedLOAModel.array(),
}))
