import * as z from "zod"
import * as imports from "../zod-schemas"
import { UtilityType, QuoteStatus } from "@prisma/client"
import { CompleteSiteMeter, RelatedSiteMeterModel, CompleteProvider, RelatedProviderModel, CompleteElectricityUsage, RelatedElectricityUsageModel, CompleteGasUsage, RelatedGasUsageModel, CompleteElectricitySupplier, RelatedElectricitySupplierModel, CompleteGasSupplier, RelatedGasSupplierModel, CompleteQuote, RelatedQuoteModel, CompleteEmailQuote, RelatedEmailQuoteModel, CompleteProfile, RelatedProfileModel } from "./index"

export const QuoteListModel = z.object({
  id: z.string(),
  utilityType: z.nativeEnum(UtilityType),
  contractStartDate: z.date(),
  creditScore: z.number().int(),
  upliftRate: z.number(),
  status: z.nativeEnum(QuoteStatus),
  isCustomQuotesOnly: z.boolean(),
  siteMeterId: z.string(),
  currentProviderId: z.string(),
  electricityUsageId: z.string().nullish(),
  gasUsageId: z.string().nullish(),
  electricitySupplierId: z.string().nullish(),
  gasSupplierId: z.string().nullish(),
  createdById: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteQuoteList extends z.infer<typeof QuoteListModel> {
  siteMeter: CompleteSiteMeter
  currentProvider: CompleteProvider
  electricityUsage?: CompleteElectricityUsage | null
  gasUsage?: CompleteGasUsage | null
  electricitySupplier?: CompleteElectricitySupplier | null
  gasSupplier?: CompleteGasSupplier | null
  quotes: CompleteQuote[]
  emailQuote: CompleteEmailQuote[]
  createdBy: CompleteProfile
}

/**
 * RelatedQuoteListModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedQuoteListModel: z.ZodSchema<CompleteQuoteList> = z.lazy(() => QuoteListModel.extend({
  siteMeter: RelatedSiteMeterModel,
  currentProvider: RelatedProviderModel,
  electricityUsage: RelatedElectricityUsageModel.nullish(),
  gasUsage: RelatedGasUsageModel.nullish(),
  electricitySupplier: RelatedElectricitySupplierModel.nullish(),
  gasSupplier: RelatedGasSupplierModel.nullish(),
  quotes: RelatedQuoteModel.array(),
  emailQuote: RelatedEmailQuoteModel.array(),
  createdBy: RelatedProfileModel,
}))
