import * as z from "zod"
import * as imports from "../zod-schemas"
import { UtilityType, SaleType } from "@prisma/client"
import { CompleteProvider, RelatedProviderModel, CompleteVerbalContract, RelatedVerbalContractModel, CompleteProfile, RelatedProfileModel } from "./index"

export const VerbalContractTemplateModel = z.object({
  /**
   * Unique identifier for the verbal contract
   */
  id: z.string(),
  /**
   * Filename of the verbal contract PDF
   */
  filename: z.string(),
  /**
   * MIME type of the file
   */
  type: z.string(),
  /**
   * Path of the file in storage
   */
  path: z.string(),
  /**
   * Size of the file in bytes
   */
  size: z.number().int(),
  /**
   * Version of the template
   */
  documentVersion: z.string(),
  /**
   * Friendly name generated from version and update date
   */
  friendlyName: z.string(),
  /**
   * Type of product
   */
  productType: z.string(),
  /**
   * If the record is marked as deleted
   */
  deleted: z.boolean(),
  providerId: z.string(),
  /**
   * Utility types this template is applicable for
   */
  utilityTypes: z.nativeEnum(UtilityType).array(),
  /**
   * Type of sale
   */
  saleTypes: z.nativeEnum(SaleType).array(),
  createdById: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date(),
})

export interface CompleteVerbalContractTemplate extends z.infer<typeof VerbalContractTemplateModel> {
  provider: CompleteProvider
  verbalContracts: CompleteVerbalContract[]
  createdBy: CompleteProfile
}

/**
 * RelatedVerbalContractTemplateModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedVerbalContractTemplateModel: z.ZodSchema<CompleteVerbalContractTemplate> = z.lazy(() => VerbalContractTemplateModel.extend({
  provider: RelatedProviderModel,
  /**
   * List of verbal contracts associated with this template
   */
  verbalContracts: RelatedVerbalContractModel.array(),
  createdBy: RelatedProfileModel,
}))
