import * as z from "zod"
import * as imports from "../zod-schemas"

export const ElectralinkAddressModel = z.object({
  /**
   * Masked MPAN for the address first 2 and last 2 characters of bottom line
   */
  mask_mpan: z.string(),
  /**
   * Detailed Address string.
   */
  address: z.string(),
  /**
   * Postal code of the metering point's physical address.
   */
  postcode: z.string(),
  /**
   * The encryped MPAN
   */
  encrypted_mpan: z.string(),
})
