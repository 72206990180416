import * as z from "zod"
import * as imports from "../zod-schemas"
import { UtilityType } from "@prisma/client"
import { CompleteProvider, RelatedProviderModel, CompleteProfile, RelatedProfileModel } from "./index"

export const PriceListModel = z.object({
  /**
   * Unique identifier for the price list
   */
  id: z.string(),
  /**
   * Filename of the price list
   */
  filename: z.string(),
  /**
   * Friendly name generated from version and update date
   */
  friendlyName: z.string(),
  /**
   * MIME type of the file
   */
  type: z.string(),
  /**
   * Path of the file in storage
   */
  path: z.string(),
  /**
   * Size of the file in bytes
   */
  size: z.number().int(),
  /**
   * Utility types in the price list
   */
  utilityTypes: z.nativeEnum(UtilityType).array(),
  notificationIds: z.string().array(),
  providerId: z.string(),
  createdById: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date(),
})

export interface CompletePriceList extends z.infer<typeof PriceListModel> {
  provider: CompleteProvider
  createdBy: CompleteProfile
}

/**
 * RelatedPriceListModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedPriceListModel: z.ZodSchema<CompletePriceList> = z.lazy(() => PriceListModel.extend({
  provider: RelatedProviderModel,
  createdBy: RelatedProfileModel,
}))
