import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteEntityAddress, RelatedEntityAddressModel, CompleteGasMeteringPoint, RelatedGasMeteringPointModel, CompleteGasUsage, RelatedGasUsageModel, CompleteGasSupplier, RelatedGasSupplierModel, CompleteGasSiteMeter, RelatedGasSiteMeterModel } from "./index"

export const MprnModel = z.object({
  /**
   * The unique identifier for this MPRN resource.
   */
  id: z.string(),
  /**
   * Unique Property Reference Number.
   */
  uprn: z.string().nullish(),
  /**
   * Unique Delivery Point Reference Number.
   */
  udprn: z.string().nullish(),
  /**
   * The Meter Point Administration Number value.
   */
  value: z.string(),
  entityAddressId: z.string(),
  /**
   * ID of the user who created this record. No FK constraint required atm due to no or low query volume
   */
  createdById: z.string().nullish(),
  /**
   * ID of the user who last updated this record. No FK constraint required atm due to no or low query volume
   */
  updatedById: z.string().nullish(),
  /**
   * Timestamp when this record was created.
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when this record was last updated.
   */
  updatedAt: z.date().optional(),
})

export interface CompleteMprn extends z.infer<typeof MprnModel> {
  entityAddress: CompleteEntityAddress
  gasMeteringPoints: CompleteGasMeteringPoint[]
  gasUsage: CompleteGasUsage[]
  gasSupplier: CompleteGasSupplier[]
  gasSiteMeter: CompleteGasSiteMeter[]
}

/**
 * RelatedMprnModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedMprnModel: z.ZodSchema<CompleteMprn> = z.lazy(() => MprnModel.extend({
  entityAddress: RelatedEntityAddressModel,
  gasMeteringPoints: RelatedGasMeteringPointModel.array(),
  gasUsage: RelatedGasUsageModel.array(),
  gasSupplier: RelatedGasSupplierModel.array(),
  gasSiteMeter: RelatedGasSiteMeterModel.array(),
}))
