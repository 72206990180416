import { z } from "zod";

const EmailSchema = z
  .string({
    required_error: "Required"
  })
  .trim()
  .email({
    message: "Must be a valid email"
  });

export const UserAuthSchema = z.object({
  email: EmailSchema,
  password: z
    .string({
      required_error: "Required"
    })
    .min(8, {
      message: "Password must be at least 8 characters"
    })
});

export const PasswordResetSchema = z.object({
  email: EmailSchema
});

export const PasswordUpdateSchema = z.object({
  password: z.string({
    required_error: "Required"
  })
});

export const MagicLinkSchema = z.object({
  email: EmailSchema
});

export type UserAuthSchema = z.infer<typeof UserAuthSchema>;
export type PasswordResetSchema = z.infer<typeof PasswordResetSchema>;
export type PasswordUpdateSchema = z.infer<typeof PasswordUpdateSchema>;
export type MagicLinkSchema = z.infer<typeof MagicLinkSchema>;
