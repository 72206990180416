import * as z from "zod"
import * as imports from "../zod-schemas"
import { ActivityType, UserRole } from "@prisma/client"
import { CompleteCompany, RelatedCompanyModel, CompleteCompanySite, RelatedCompanySiteModel, CompleteProfile, RelatedProfileModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const ActivityLogModel = z.object({
  /**
   * Unique identifier for the Activities
   */
  id: z.string().optional(),
  activityType: z.nativeEnum(ActivityType),
  /**
   * Entity responsible for the activity
   */
  activityUser: z.nativeEnum(UserRole),
  /**
   * Description of the activity
   */
  description: z.string(),
  /**
   * Additional metadata of the activity
   */
  additionalMetadata: jsonSchema.optional(),
  /**
   * Unique identifier for the company associated with the activity
   */
  companyId: z.string(),
  /**
   * Unique identifier for the company site associated with the utility usage
   */
  companySiteId: z.string().nullish(),
  /**
   * Id of the user who performed the activity if the activity user is not a system
   */
  createdById: z.string().optional().nullish(),
  /**
   * Timestamp when the activity occurred
   */
  createdAt: z.date().optional(),
})

export interface CompleteActivityLog extends z.infer<typeof ActivityLogModel> {
  company: CompleteCompany
  companySite?: CompleteCompanySite | null
  createdBy?: CompleteProfile | null
}

/**
 * RelatedActivityLogModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedActivityLogModel: z.ZodSchema<CompleteActivityLog> = z.lazy(() => ActivityLogModel.extend({
  /**
   * Company associated with the activity
   */
  company: RelatedCompanyModel,
  /**
   * Company site associated with the utility usage
   */
  companySite: RelatedCompanySiteModel.nullish(),
  /**
   * Profile of the user who performed the activity
   */
  createdBy: RelatedProfileModel.nullish(),
}))
