import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteMprn, RelatedMprnModel } from "./index"

export const GasMeteringPointModel = z.object({
  /**
   * Unique identifier for the gas metering point.
   */
  id: z.string(),
  /**
   * Unique Property Reference Number.
   */
  uprn: z.string(),
  /**
   * Unique Delivery Point Reference Number.
   */
  udprn: z.string().nullish(),
  /**
   * Primary name of the related address.
   */
  relAddressPrimaryName: z.string(),
  /**
   * Secondary name of the related address.
   */
  relAddressSecondaryName: z.string().nullish(),
  /**
   * First street line of the related address.
   */
  relAddressStreet1: z.string(),
  /**
   * Second street line of the related address.
   */
  relAddressStreet2: z.string().nullish(),
  /**
   * First locality of the related address.
   */
  relAddressLocality1: z.string().nullish(),
  /**
   * Second locality of the related address.
   */
  relAddressLocality2: z.string().nullish(),
  /**
   * Town of the related address.
   */
  relAddressTown: z.string(),
  /**
   * Postcode of the related address.
   */
  relAddressPostcode: z.string(),
  /**
   * Logical status of the related address.
   */
  relAddressLogicalStatus: z.string(),
  /**
   * Language of the related address.
   */
  relAddressLanguage: z.string(),
  /**
   * Organisation of the related address.
   */
  relAddressOrganisation: z.string().nullish(),
  /**
   * EntityAddress type of the related address.
   */
  relAddressAddressType: z.string(),
  /**
   * Confidence score of the related address.
   */
  relAddressConfidenceScore: z.number().int(),
  /**
   * Classification of the related address.
   */
  relAddressClassification: z.string(),
  /**
   * Latitude of the related address.
   */
  relAddressLatitude: z.number(),
  /**
   * Longitude of the related address.
   */
  relAddressLongitude: z.number(),
  /**
   * Serial number of the meter.
   */
  meterSerial: z.string(),
  /**
   * Annual offtake quantity.
   */
  offtakeQuantityAnnual: z.number().int(),
  /**
   * Status of the meter point.
   */
  meterPointStatus: z.string(),
  /**
   * Installer ID.
   */
  installerId: z.string(),
  /**
   * Name of the network.
   */
  networkName: z.string(),
  /**
   * Name of the supplier.
   */
  supplierName: z.string(),
  /**
   * Date of the last meter reading.
   */
  lastMeterReadDate: z.date(),
  /**
   * Type of the last meter reading.
   */
  lastMeterReadType: z.string(),
  /**
   * Value of the last meter reading.
   */
  lastMeterReadValue: z.string(),
  /**
   * Timestamp when the gas metering point record was created.
   */
  createdAt: z.date(),
  /**
   * Timestamp when the gas metering point record was last updated.
   */
  updatedAt: z.date(),
  mprnId: z.string(),
})

export interface CompleteGasMeteringPoint extends z.infer<typeof GasMeteringPointModel> {
  mprn: CompleteMprn
}

/**
 * RelatedGasMeteringPointModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedGasMeteringPointModel: z.ZodSchema<CompleteGasMeteringPoint> = z.lazy(() => GasMeteringPointModel.extend({
  /**
   * Meter Point Reference Number, unique identifier for every gas metering point in the UK.
   */
  mprn: RelatedMprnModel,
}))
