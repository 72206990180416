import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteCompany, RelatedCompanyModel } from "./index"

export const CreditScoreModel = z.object({
  id: z.string(),
  creditScore: z.number().int().nullish(),
  companyId: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteCreditScore extends z.infer<typeof CreditScoreModel> {
  company: CompleteCompany
}

/**
 * RelatedCreditScoreModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCreditScoreModel: z.ZodSchema<CompleteCreditScore> = z.lazy(() => CreditScoreModel.extend({
  company: RelatedCompanyModel,
}))
