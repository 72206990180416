// import electricQuoteDefinitions from "./electric-quote-definitions.json";
// import gasQuoteDefinitions from "./gas-quote-definitions.json";
import { DEFAULT_CAPACITY_FIGURE_KVA } from "./default-capacity-figure-kva";
import tprReference from "./tpr-reference.json";
import udProvidersList from "./ud-providers-list.json";
import upliftMaxima from "./uplift-maxima.json";
import { PRIVILEGED_ROLES } from "./user-role-permissions";

export {
  // electricQuoteDefinitions,
  // gasQuoteDefinitions,
  tprReference,
  upliftMaxima,
  udProvidersList,
  DEFAULT_CAPACITY_FIGURE_KVA,
  PRIVILEGED_ROLES
};
