import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteProfile, RelatedProfileModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const AuthUserModel = z.object({
  instance_id: z.string().nullish(),
  id: z.string(),
  aud: z.string().nullish(),
  role: z.string().nullish(),
  email: z.string().nullish(),
  encrypted_password: z.string().nullish(),
  email_confirmed_at: z.date().nullish(),
  invited_at: z.date().nullish(),
  confirmation_token: z.string().nullish(),
  confirmation_sent_at: z.date().nullish(),
  recovery_token: z.string().nullish(),
  recovery_sent_at: z.date().nullish(),
  email_change_token_new: z.string().nullish(),
  email_change: z.string().nullish(),
  email_change_sent_at: z.date().nullish(),
  last_sign_in_at: z.date().nullish(),
  raw_app_meta_data: jsonSchema,
  raw_user_meta_data: jsonSchema,
  is_super_admin: z.boolean().nullish(),
  created_at: z.date().nullish(),
  updated_at: z.date().nullish(),
  phone: z.string().nullish(),
  phone_confirmed_at: z.date().nullish(),
  phone_change: z.string().nullish(),
  phone_change_token: z.string().nullish(),
  phone_change_sent_at: z.date().nullish(),
  confirmed_at: z.date().nullish(),
  email_change_token_current: z.string().nullish(),
  email_change_confirm_status: z.number().int().nullish(),
  banned_until: z.date().nullish(),
  reauthentication_token: z.string().nullish(),
  reauthentication_sent_at: z.date().nullish(),
  is_sso_user: z.boolean(),
  deleted_at: z.date().nullish(),
})

export interface CompleteAuthUser extends z.infer<typeof AuthUserModel> {
  profile: CompleteProfile[]
}

/**
 * RelatedAuthUserModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedAuthUserModel: z.ZodSchema<CompleteAuthUser> = z.lazy(() => AuthUserModel.extend({
  profile: RelatedProfileModel.array(),
}))
