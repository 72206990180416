import * as z from "zod"
import * as imports from "../zod-schemas"
import { PhoneNumberType } from "@prisma/client"
import { CompleteCompanyContact, RelatedCompanyContactModel, CompleteProfile, RelatedProfileModel } from "./index"

export const CompanyContactPhoneNumberModel = z.object({
  id: z.string(),
  phoneNumber: z.string(),
  type: z.nativeEnum(PhoneNumberType),
  isPrimary: z.boolean(),
  contactId: z.string(),
  createdById: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteCompanyContactPhoneNumber extends z.infer<typeof CompanyContactPhoneNumberModel> {
  contact: CompleteCompanyContact
  createdBy: CompleteProfile
}

/**
 * RelatedCompanyContactPhoneNumberModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedCompanyContactPhoneNumberModel: z.ZodSchema<CompleteCompanyContactPhoneNumber> = z.lazy(() => CompanyContactPhoneNumberModel.extend({
  contact: RelatedCompanyContactModel,
  createdBy: RelatedProfileModel,
}))
