import * as z from "zod"
import * as imports from "../zod-schemas"
import { NoteProfileType } from "@prisma/client"
import { CompleteCompany, RelatedCompanyModel, CompleteCompanySite, RelatedCompanySiteModel, CompleteSiteMeter, RelatedSiteMeterModel, CompleteProfile, RelatedProfileModel } from "./index"

export const NoteModel = z.object({
  id: z.string().optional(),
  profileType: z.nativeEnum(NoteProfileType),
  title: z.string().optional().nullish(),
  description: z.string().optional().nullish(),
  companyId: z.string(),
  siteId: z.string().optional().nullish(),
  createdById: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
  isHidden: z.boolean().nullish(),
  hiddenUpdatedById: z.string().nullish(),
  hiddenUpdatedAt: z.date().nullish(),
})

export interface CompleteNote extends z.infer<typeof NoteModel> {
  company: CompleteCompany
  site?: CompleteCompanySite | null
  siteMeters: CompleteSiteMeter[]
  createdBy: CompleteProfile
  hiddenUpdatedBy?: CompleteProfile | null
}

/**
 * RelatedNoteModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedNoteModel: z.ZodSchema<CompleteNote> = z.lazy(() => NoteModel.extend({
  company: RelatedCompanyModel,
  site: RelatedCompanySiteModel.nullish(),
  siteMeters: RelatedSiteMeterModel.array(),
  createdBy: RelatedProfileModel,
  hiddenUpdatedBy: RelatedProfileModel.nullish(),
}))
