import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteElectricMeteringPoint, RelatedElectricMeteringPointModel } from "./index"

export const MeteringPointAdditionalDetailModel = z.object({
  /**
   * Unique identifier for the additional detail entry.
   */
  id: z.string(),
  /**
   * Foreign key connecting back to the MeteringPoint model.
   */
  meteringPointId: z.string(),
  /**
   * Meter Serial Number, the unique identifier of the electricity meter.
   */
  msn: z.string(),
  /**
   * Type of electricity meter installed (e.g., smart meter, single rate meter).
   */
  meterType: z.string(),
  /**
   * Date the meter was installed.
   */
  makeInstallDate: z.date(),
  /**
   * Make and model of the electricity meter.
   */
  makeModel: z.string(),
  /**
   * ID for the Meter Operator (MOp), the company responsible for installing and maintaining the meter.
   */
  smsoId: z.string().nullish(),
  /**
   * Distribution Network Operator (DNO) identification number.
   */
  dnoId: z.string(),
  /**
   * Profile class categorising the expected electricity usage pattern.
   */
  profileClass: z.string(),
  /**
   * Meter Timeswitch Code, indicating the type of metering in terms of the time of day rate switches.
   */
  mtcId: z.string(),
  /**
   * Grid Supply Point group identifier, linking to the National Grid supply group.
   */
  gspGroupId: z.string(),
  /**
   * Class indicating the precision of measurement for energy consumption.
   */
  measurementClass: z.string(),
  /**
   * Effective From Date for the measurement class.
   */
  mcEfd: z.date(),
  /**
   * Timestamp when the additional detail record was created.
   */
  createdAt: z.date(),
  /**
   * Timestamp when the additional detail record was last updated.
   */
  updatedAt: z.date(),
})

export interface CompleteMeteringPointAdditionalDetail extends z.infer<typeof MeteringPointAdditionalDetailModel> {
  meteringPoint: CompleteElectricMeteringPoint
}

/**
 * RelatedMeteringPointAdditionalDetailModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedMeteringPointAdditionalDetailModel: z.ZodSchema<CompleteMeteringPointAdditionalDetail> = z.lazy(() => MeteringPointAdditionalDetailModel.extend({
  /**
   * Relation to the MeteringPoint this additional detail is associated with.
   */
  meteringPoint: RelatedElectricMeteringPointModel,
}))
