import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteEntityAddress, RelatedEntityAddressModel, CompleteCompanySite, RelatedCompanySiteModel, CompleteProfile, RelatedProfileModel } from "./index"

export const BillingAddressModel = z.object({
  /**
   * Unique identifier for the billing address
   */
  id: z.string(),
  /**
   * Relation to EntityAddress
   */
  entityAddressId: z.string(),
  companySiteId: z.string(),
  createdById: z.string(),
  /**
   * Timestamp when the billing address was created
   */
  createdAt: z.date().optional(),
  /**
   * Timestamp when the billing address was last updated
   */
  updatedAt: z.date().optional(),
})

export interface CompleteBillingAddress extends z.infer<typeof BillingAddressModel> {
  entityAddress: CompleteEntityAddress
  companySites: CompleteCompanySite
  createdBy: CompleteProfile
}

/**
 * RelatedBillingAddressModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedBillingAddressModel: z.ZodSchema<CompleteBillingAddress> = z.lazy(() => BillingAddressModel.extend({
  entityAddress: RelatedEntityAddressModel,
  companySites: RelatedCompanySiteModel,
  createdBy: RelatedProfileModel,
}))
