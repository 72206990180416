import * as z from "zod"
import * as imports from "../zod-schemas"
import { CompleteQuoteList, RelatedQuoteListModel, CompleteQuote, RelatedQuoteModel, CompleteEmailTransaction, RelatedEmailTransactionModel } from "./index"

export const EmailQuoteModel = z.object({
  id: z.string(),
  quoteListId: z.string(),
  subject: z.string(),
  emailTransactionId: z.string().nullish(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteEmailQuote extends z.infer<typeof EmailQuoteModel> {
  quoteList: CompleteQuoteList
  quotes: CompleteQuote[]
  emailTransaction?: CompleteEmailTransaction | null
}

/**
 * RelatedEmailQuoteModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedEmailQuoteModel: z.ZodSchema<CompleteEmailQuote> = z.lazy(() => EmailQuoteModel.extend({
  quoteList: RelatedQuoteListModel,
  quotes: RelatedQuoteModel.array(),
  emailTransaction: RelatedEmailTransactionModel.nullish(),
}))
