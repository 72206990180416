import * as z from "zod"
import * as imports from "../zod-schemas"
import { EmailTransactionEvents } from "@prisma/client"
import { CompleteEmailTransaction, RelatedEmailTransactionModel, CompleteProfile, RelatedProfileModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const EmailEventModel = z.object({
  /**
   * Unique identifier for the email transaction
   */
  id: z.string().optional(),
  /**
   * Unique message ID assigned by the email service provider
   */
  messageId: z.string(),
  /**
   * a unique ID attached to the message by the originating system.
   */
  smtpId: z.string().nullish(),
  /**
   * the email address of the recipient
   */
  email: z.string().email(),
  /**
   * the UNIX timestamp of when the message was sent
   */
  timestamp: z.number().int(),
  /**
   * the event type
   */
  event: z.nativeEnum(EmailTransactionEvents).nullish(),
  /**
   * the IP address used to send the email
   */
  ip: z.string().nullish(),
  /**
   * a unique ID to this event that you can use for deduplication purposes
   */
  sgEventId: z.string(),
  /**
   * a unique, internal SendGrid ID for the message
   */
  sgMessageId: z.string(),
  mcPodId: z.string().nullish(),
  mcStats: z.string().nullish(),
  phaseId: z.string().nullish(),
  sendAt: z.string().nullish(),
  sgContentType: z.string().nullish(),
  sgTemplateId: z.string().nullish(),
  sgTemplateName: z.string().nullish(),
  singlesendId: z.string().nullish(),
  singlesendName: z.string().nullish(),
  templateHash: z.string().nullish(),
  templateVersionId: z.string().nullish(),
  /**
   * Any error response returned by the receiving server
   */
  reason: z.string().nullish(),
  /**
   * Status code string
   */
  status: z.string().nullish(),
  /**
   * The full text of the HTTP response error returned from the receiving server
   */
  response: z.string().nullish(),
  /**
   * Indicates whether TLS encryption was used
   */
  tls: z.number().int().nullish(),
  /**
   * The URL where the event originates
   */
  url: z.string().nullish(),
  /**
   * Indicates the location of a link in the email's HTML code. Indices are set starting from top to bottom in the HTML, meaning the first link read from top to bottom will be at index 0. The url_offset is provided so that you may identify a link when reviewing metrics such as clicks. For example, if the same link appears twice in your email, once in the intro copy and once at the bottom in a call to action, you can identify which link was clicked by its index. The first link, appearing earlier in the HTML, will have a lower index.
   */
  urlOffsetIndex: z.number().int().nullish(),
  urlOffsetType: z.string().nullish(),
  /**
   * The number of times an attempt has been made to deliver this message
   */
  attempt: z.string().nullish(),
  /**
   * Categories (handled in application logic, stored as a string representation of an array)
   */
  category: z.string().array().optional(),
  /**
   * Indicates the type of bounce event
   */
  type: z.string().nullish(),
  /**
   * Indicates whether the event was machine-opened
   */
  sgMachineOpen: z.boolean().nullish(),
  /**
   * Classification of the bounce event
   */
  bounceClassification: z.string().nullish(),
  /**
   * The ID of the unsubscribe group the recipient's email address is included in
   */
  asmGroupId: z.number().int().nullish(),
  /**
   * Unique arguments sent with the email (stored as a string representation of an object) - v2 of API only ignore
   */
  uniqueArgs: z.string().nullish(),
  customArgs: jsonSchema,
  /**
   * the user agent responsible for the event
   */
  createdById: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional().nullish(),
})

export interface CompleteEmailEvent extends z.infer<typeof EmailEventModel> {
  emailTransaction?: CompleteEmailTransaction | null
  createdBy: CompleteProfile
}

/**
 * RelatedEmailEventModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedEmailEventModel: z.ZodSchema<CompleteEmailEvent> = z.lazy(() => EmailEventModel.extend({
  emailTransaction: RelatedEmailTransactionModel.nullish(),
  createdBy: RelatedProfileModel,
}))
