import * as z from "zod"
import * as imports from "../zod-schemas"
import { UtilityType, QuoteStatus, QuoteType } from "@prisma/client"
import { CompleteProvider, RelatedProviderModel, CompleteQuoteList, RelatedQuoteListModel, CompleteContract, RelatedContractModel, CompleteGasQuote, RelatedGasQuoteModel, CompleteElectricityQuote, RelatedElectricityQuoteModel, CompleteEmailQuote, RelatedEmailQuoteModel } from "./index"

export const QuoteModel = z.object({
  id: z.string(),
  utilityType: z.nativeEnum(UtilityType),
  active: z.boolean(),
  duration: z.number().int(),
  endDate: z.date(),
  status: z.nativeEnum(QuoteStatus),
  type: z.nativeEnum(QuoteType),
  providerId: z.string(),
  quoteListId: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
})

export interface CompleteQuote extends z.infer<typeof QuoteModel> {
  provider: CompleteProvider
  quoteList: CompleteQuoteList
  contract?: CompleteContract | null
  gasQuote?: CompleteGasQuote | null
  electricQuote?: CompleteElectricityQuote | null
  emailQuotes: CompleteEmailQuote[]
}

/**
 * RelatedQuoteModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedQuoteModel: z.ZodSchema<CompleteQuote> = z.lazy(() => QuoteModel.extend({
  provider: RelatedProviderModel,
  quoteList: RelatedQuoteListModel,
  contract: RelatedContractModel.nullish(),
  gasQuote: RelatedGasQuoteModel.nullish(),
  electricQuote: RelatedElectricityQuoteModel.nullish(),
  emailQuotes: RelatedEmailQuoteModel.array(),
}))
